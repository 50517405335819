.gutenberg-video {
    padding: 4.5rem $padding;
  position: relative;

  h2 {
    @extend .h3;
    margin-bottom: 4.5rem;
  }

  .title {
    text-align: center;
  }
  .item {
    position: relative;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
    &.active {
      opacity: 0;
    }
  }
  video {
    width: 100%;
  }
}