.page-template-page-collections {
  .hr-title {
    padding: 2.4rem $padding;
    text-align: center;
    display: block;
    border-top: solid 1px $beige_b;
  }

  .miniature-vin {
    overflow: hidden;
  }

  .collection-produits {
    .items {
      @media screen and (min-width: $width-medium) {
        border-top: none;
      }
    }

  }

  #content {
    overflow: hidden;
  }

  .gutenberg-hero-title {
    @media screen and (min-width: $width-medium) {
      height: auto;
      padding-top: 210px;
      padding-bottom: 90px;
    }
    .sous-titre {
      display: block;
      text-align: center;
      margin-top: -2.5rem;
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }



  .collections {
    .item {
      border-top: solid 1px $beige_b;
      @media screen and (max-width: $width-medium) {
        border: none;
      }
    }
    .line {
      display: flex;
      border-bottom: solid 1px $beige_b;
      @media screen and (max-width: $width-medium) {
        flex-direction: column-reverse;
      }

      .left {

        padding: 5rem 3rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-right: solid 1px $beige_b;
        position: relative;

        @media screen and (min-width: $width-large) {
          width: 37.5%;
          flex-basis: 37.5%;
          padding: 0 12rem;
        }
        @media screen and (max-width: $width-medium) {
          border-top: solid 1px $beige_b;
        }
        .text-link {
          margin-top: 2.5rem;
        }

        .arrow {
          @media screen and (max-width: $width-medium) {
         width: 100%;
            margin-top: 3.5rem;
            text-align: center;
          }
          @media screen and (min-width: $width-medium) {
            bottom: 5rem;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
          }
        }

        .h5 {
          p {
            @extend .h5;
          }
        }
      }

      .right {

        @media screen and (min-width: $width-large) {
          width: 62.5%;
          flex-basis: 62.5%;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      @media screen and (max-width: $width-medium) {
        border: none;
      }

      &:last-child {
        .left {
          @media screen and (max-width: $width-medium) {
            display: none;
          }
        }
        .right {
          @media screen and (max-width: $width-medium) {
            padding: 0rem 3rem;
            padding-bottom: 5rem;
          }
          @media screen and (min-width: $width-medium) {
            padding: 5rem 6rem;
          }
        }
      }

      &:first-child {
        .left {
          background-image: url(../img/collections-barbaccus-bg.svg);
          background-repeat: no-repeat;
          background-size: 13vw auto;
          background-position: left 3rem;
          @media screen and (max-width: $width-medium) {
            background-size: 140px auto;;
          }
        }
      }

    }

    .items {
      &.count-1 {
        justify-content: center;
        .miniature-vin {
          border-left: solid 0.5px #ddd6cc;
          border-right: solid 0.5px #ddd6cc;
        }
      }
    }
  }

  #menu-collection-mobile {
    display: none;
  }
  @media screen and (max-width: $width-medium) {
    #menu-collection-mobile {
      transition: all .2s ease-out;
      background: $white;
      align-items: center;
      justify-content: center;
      text-decoration: underline;
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: fixed;
      top: 0;
      z-index: 1000;
      left: 0;
      right: 0;
      display: none;

      &.active {
        display: flex;
        display: none;
      }

    }

    &.fixed-menu {
      #menu-collection-mobile {
        top: 4.5rem;
      }
    }
  }

  #menu-collection {
    transition: all .2s ease-out;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $width-medium) {
        display: block;
      }
      &:last-child {
        border-bottom: solid 1px $beige_b;
      }
      .label {
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 2rem;
        margin-bottom: 0;
        @extend .h5;
        flex-direction: column;
        align-items: flex-end;
        @media screen and (min-width: $width-medium) {
          flex-basis: 200px;
          justify-content: end;
        }
        @media screen and (min-width: $width-large) {
          flex-basis: 30%;
          justify-content: end;
        }
        @media screen and (max-width: $width-medium) {
          display: block;
          text-align: center;
          border: none;
          padding-right: 0;
        }
        div:first-child {
          @media screen and (min-width: $width-medium) {
            height: 145px;
            display: flex;
            align-items: center;
            .arrow-round {
              display: none;
            }
          }
          @media screen and (max-width: $width-medium) {
            position: relative;
            padding: 2rem $padding;
            .arrow-round {
              position: absolute;
              right: 2rem;
              top: 50%;
              margin-top: -20px;
              background-color: $white;
              transform: rotate(0deg);
            }
          }

        }
        #toggleFilter.active {
          @media screen and (max-width: $width-medium) {
            .arrow-round {
              background-color: transparent;
              transform: rotate(180deg);
            }
          }
        }
        div:last-child {
          letter-spacing: 0;
          width: 100%;
          text-align: right;
          margin-right: -2rem;
          padding-right: 2rem;
          padding-top: 1.6rem;
          padding-bottom: 1.6rem;
          border-top: solid 1px $beige_b;
          @media screen and (max-width: $width-medium) {
            display: none;
          }
          a {
            font-family: $font-p;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 19.5px */
            color: $black_p;
            display: flex;
            justify-content: end;
            gap: 1rem;
          }
        }
      }
      .filters {
        padding-top: 2rem;
        padding-bottom: 2rem;
        @media screen and (min-width: $width-medium) {
          flex-basis: calc(100% - 200px);
          border-left: solid 1px $beige_b;
        }
        @media screen and (min-width: $width-large) {
          flex-basis: 65%;
          border-left: solid 1px $beige_b;
        }
      }
      &:first-child {
        background: $white;
        transition: all .2s ease-out;
        .filters {
          @media screen and (max-width: $width-medium) {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        @media screen and (max-width: $width-medium) {
          height: auto;
          padding-top: 1.5rem;
          padding-bottom: 1rem;
        }
      }

      .reinit {
        display: none;
        @media screen and (max-width: $width-medium) {
          justify-content: center;
          align-items: center;
          padding: 2rem $padding;
          &.active {
            display: flex;
          }
          a {
            font-family: Inter,sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: #414141;
            display: flex;
            justify-content: end;
            gap: 1rem;
          }
        }
      }
    }

    .collection-filter {
      display: flex;
      padding-left: 4rem;
      list-style: none;

      @media screen and (min-width: $width-medium) {
        padding-right: 4rem;
        gap: 4rem;
      }
      @media screen and (max-width: $width-medium) {
        gap: 1rem;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        justify-content: center;
      }
      @media screen and (max-width: $width-small) {
        justify-content: left;
      }

      li {
        a {
          cursor: pointer;
          display: flex;
          padding: 7px 20px;
          justify-content: center;
          align-items: center;
          border-radius: 400px;
          border: solid 1px $beige_b;
          font-family: $font-t;
          font-size: 2.2rem;
          font-style: italic;
          text-align: center;
          transition: all .2s ease-out;
          flex-direction: column;
        }

        &.active {
          a {
            background: $black;
            color: $white;
            border-color: $black;
          }

        }
        &:hover {
          a {
            background: $beige-b;
            color: $black;
            border-color: $beige_b;
          }

        }
        &:before {
          display: none !important;
        }
        a {
          line-height: 1.2;
          span {
            display: block;
          }
          .span {
            font-style: normal;
            text-transform: uppercase;
            font-family: $font-p;
            font-size: 1rem;

          }
        }

      }
    }

    .filters-vin {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      @media screen and (max-width: $width-medium) {
        justify-content: center;
        padding: 0 $padding;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        display: none;
        &.active {
          display: block;
        }
      }
      @media screen and (min-width: $width-medium) {
        padding-left: 4rem;
      }

      .grid {
        @media screen and (max-width: $width-medium) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, auto);
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
        }
        @media screen and (min-width: $width-medium) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 5rem;
          grid-row-gap: 2rem;
          align-items: flex-start;
          max-width: 670px;
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        #ajax-filter-couleur {
          @media screen and (min-width: $width-medium) {
            grid-area: 1 / 1 / 3 / 2;
          }
          @media screen and (max-width: $width-medium) {
            grid-area: 1 / 1 / 2 / 2;
          }
          ul {
            flex-direction: column;
          }

        }
        #ajax-filter-caracteristiques {
          @media screen and (min-width: $width-medium) {
            grid-area: 1 / 2 / 3 / 3;
          }
          @media screen and (max-width: $width-medium) {
            grid-area: 1 / 2 / 2 / 3;
          }
          ul {
            flex-direction: column;
          }
        }

        #ajax-filter-dominance {
          @media screen and (min-width: $width-medium) {
            grid-area: 1 / 3 / 2 / 4;
            padding-right: 2rem;
          }
          @media screen and (max-width: $width-medium) {
            grid-area: 2 / 1 / 3 / 3;
            margin-top: 2rem;
          }
        }

        #ajax-filter-appellation {
          @media screen and (min-width: $width-medium) {
            grid-area: 2 / 3 / 3 / 4;
            padding-right: 2rem;
          }
          @media screen and (max-width: $width-medium) {
            grid-area: 3 / 1 / 4 / 3;
          }
        }

      }

      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 370px;
        flex-basis: 370px;
        row-gap: 1.5rem;
        @media screen and (max-width: $width-medium) {
          max-width: 316px;
          flex-basis: 316px;
        }
        li {
          line-height: 1 !important;
          font-size: 1.3rem;
         &:before {
           display: none;
         }
          input.strikethrough + label {
            text-decoration: line-through;
          }

          &.count-0 {
            opacity: 0.5;
            pointer-events: none;
            order: 100;
          }

        }
      }

      label {
        padding-left: 8px;
        cursor: pointer;
      }

      input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid $beige_b;
        background-color: #fff;
        cursor: pointer;
        border-radius: 2px;


        &::before {
          content: '\2713';
          display: inline-block;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          color: transparent;
        }
      }

      input[type="checkbox"]:checked {
        background-color: $or;
        borde-color: $or;
        color: $white;
        &::before {
          color: $white;
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        box-shadow: none;
        font-size: 1.5rem;
        background: transparent;
        border: solid 1px #ddd6cc;
        border-radius: 400px;
        padding: 16px 20px;
        max-width: 100%;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63545 5.53553L1.6857 0.585786L0.271484 2L6.63545 8.36396L12.9994 2L11.5852 0.585786L6.63545 5.53553Z" fill="%23414141"/></svg>');
        background-size: 13px 9px;
        background-position: calc(100% - 2rem) center;
        background-repeat: no-repeat;
        padding-right: 5rem;
        width: 300px;
        color: $black;
        @media screen and (max-width: $width-medium) {
          width: 100%;
        }
      }
    }


      &.fixed {
        .more-filters {
          display: block;
        }
        .item {
          &:first-child {
            @media screen and (min-width: $width-medium) {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              z-index: 100;
            }
            @media screen and (max-width: $width-medium) {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              z-index: 100;
            }
          }
        }
      }


    .more-filters {
      display: none;
      text-decoration: underline;
      @media screen and (min-width: $width-medium) {
        position: absolute;
        right: 2rem;
        top: 50%;
        margin-top: -10px;
      }
      @media screen and (min-width: $width-xlarge) {
        right: 8rem;
      }
      @media screen and (max-width: $width-medium) {
       text-align: center;
        padding: 0 $padding;
        position: absolute;
        right: 0;
        top: 2rem;
      }

      .mobile {
        display: none;
      }

    }

  }

  @media screen and (min-width: $width-medium) {
    &.fixed-menu {
      #menu-collection {
        &.fixed {
          .item:first-child {
            top: 7.5rem;
          }

        }
      }

    }
  }

  #ajax-filter-filters {
    color: $black_p;
    div {
      padding: 1.5rem $padding;
      text-align: center;
      border-bottom: solid 1px $beige_b;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .italic {
        font-size: 1.3rem;
        font-style: italic;
        margin-right: 1.3rem;

      }
      .parent-item-filter {
        min-width: 90px;
        border-radius: 200px;
        border: solid 1px $beige_b;
        padding: 5px 14px;
        display: inline-block;
        text-align: left;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        label {
          cursor: pointer;
          padding-right: 2rem;
          &:after {
            display: inline-block;
            content: "";
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><path d="M4.24219 4.36377L12.7275 12.8491" stroke="%23414141"/><path d="M4.24219 12.8491L12.7275 4.36384" stroke="%23414141"/></svg>');
            background-size: 100% 100%;
            width: 17px;
            height: 18px;
            background-repeat: no-repeat;
            position: absolute;
            right: 1rem;
            top: 50%;
            margin-top: -9px;
          }
        }

        input {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }

}