#instagram {
  padding-left: 10%;
  padding-top: 5rem;
  padding-bottom: 3rem;

  width: 100%;
  overflow: hidden;
  h2 {
    @extend .h4;
    font-style: italic !important;
    margin-bottom: 1rem;
  }
  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    margin-top: 1.5rem;
    .content {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      aspect-ratio: 1;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      -o-object-fit: cover;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }
  .swiper-navigation {
    position: absolute;
    right: 6rem;
    top: -8rem;
    width: 110px;
    justify-content: space-between;
    @media screen and (max-width: $width-small) {
      display: none;
    }
  }

  .hover {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .content {
    a {
      &:hover {
        .hover {
          opacity: 1;
        }
      }
    }
  }

}