.page-template-page-contact {
  #footer {
    margin-top: 0;
  }
  h1 {
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    text-align: center;

  }
  #container {
    padding-top: 8rem;
    padding-left: $padding;
    padding-right: $padding;
    #breadcrumb {
      padding: 0 2.5rem;
      @media screen and (max-width: $width-small) {
        padding: 0;
        margin-left: -1rem;
      }
    }

    &:before {
      @media screen and (max-width: $width-small) {
        display: block;
        content: "";
        position: absolute;
        background-image: url(../img/volute-hero.svg);
        background-repeat: no-repeat;
        background-position: right bottom;
        right: 0;
        width: 100%;
        z-index: -1 ;
        top: 25rem;
        height: 80vw;
        background-size: 150% auto;
      }
    }
  }
  .symbol {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    display: block;
    margin-top: 2rem;
    font-family: $font-t;
    font-style: italic;
    line-height: 150%;
    @media screen and (max-width: $width-small) {
      text-align: center;
      font-size: 1.8rem;
    }
    @media screen and (min-width: $width-small) {
      font-size: 2.2rem;
    }
  }

  .part-two {
    .wpcf7 {
      form {
        margin-top: 5rem;

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63545 5.53553L1.6857 0.585786L0.271484 2L6.63545 8.36396L12.9994 2L11.5852 0.585786L6.63545 5.53553Z" fill="%23414141"/></svg>');
          background-size: 13px 9px;
          background-position: calc(100% - 2rem) center;
          background-repeat: no-repeat;
          padding-right: 5rem;
        }

        select[name="menu-civility"] {
          width: auto;
          @media screen and (min-width: $width-small) {
            width: 100%;
          }
        }

        select[name="menu-object"] {
          width: 250px;
          @media screen and (max-width: $width-medium) {
            width: 100%;
          }
        }

        .group-2 {
          .item-40:nth-child(2) {
            @media screen and (min-width: $width-medium) {
              width: calc(40% - 15px);
              flex-basis: calc(40% - 15px);
            }
          }
        }
      }
    }
  }

  .entry-content {
    @media screen and (min-width: $width-small) {
      display: flex;
      border-top: solid 1px $beige_b;
      margin-left: -3rem;
      margin-right: -3rem;
      border-bottom: solid 1px $beige_b;
    }
    .part-one {
      @media screen and (min-width: $width-small) {
        flex-basis: 360px;
        width: 360px;
        border-right: solid 1px $beige_b;
        .content {
          width: 170px;
          margin-right: 8rem;
          float: right;
          margin-top: 9rem;
        }
      }
    }

    .part-two {

      @media screen and (min-width: $width-small) {
        padding-left: 6rem;
        padding-top: 3rem;
        padding-bottom: 5rem;
        padding-right: 3rem;
      }
    }
  }

}