#footer {
  padding: 30px;
  position: relative;
  &:before {
    display: block;
    content: "";
    background-image: url("../img/volute.svg");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    @media screen and (min-width: $width-small) {
      background-size: 376px auto;
      top: -140px;
      bottom: 0;
      height: auto;
      left: -40px;
    }
  }

  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    &.logo {
      filter: invert(10%) sepia(11%) saturate(275%) hue-rotate(289deg) brightness(98%) contrast(86%);
    }
  }

  .pictos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    opacity: 0.5;
    margin-top: 24px;
    @media screen and (min-width: $width-small) {
      margin-top: -45px;
      justify-content: end;
    }
  }

  .menu-footer-inner {
    border-top: solid 1px $beige_b;
    padding-top: 25px;
    margin-top: 25px;
    ul {
      text-align: center;
      list-style: none;
      @media screen and (min-width: $width-small) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        margin-bottom: 25px;
      }
      @media screen and (min-width: 1026px) {
          max-width: 1300px;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
      }
      li {
        @media screen and (max-width: $width-small) {
          margin-bottom: 20px;
        }
        a {
          opacity: 0.6;
          font-size: 1.3rem;
          &:hover {
            opacity: 1;
          }
        }
        &.current_page_item {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  p {
    opacity: 0.6;
    font-size: 1.2rem;
    text-align: center;
  }
}

.back-to-top {
  position: fixed;
  right: 16px;
  bottom: 50px;
  z-index: 100;
  opacity: 0;
  transition: opacity 1s;
 }


#tarteaucitronRoot #tarteaucitronAlertBig {
  width: calc(100% - 60px) !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  background: #2b2829 !important;
}

#tarteaucitronRoot button#tarteaucitronPrivacyUrl {
  margin-left: 10px;
}
@media screen and (max-width: $width-medium) {

  #tarteaucitronRoot #tarteaucitronAlertBig {
    width: calc(100% - 40px) !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  #tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
    font-size: 14px !important;
    margin-bottom: 12px;
  }


  #tarteaucitronRoot button.tarteaucitronCTAButton {
    margin-bottom: 10px;
  }

}