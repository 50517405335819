#header {
  position: absolute;
  width: 100%;
  z-index: 100;
  padding: 1rem 1.5rem;
  transition: all 0.2s ease-out;
  top: 0;
  &.fixed {
    background: $beige;
    position: fixed;
    top: 0;
    color:$black;
  }

  #menu-toggle {
    opacity: 0;
  }

  #menu-icon {
    position: absolute;
    z-index: 100;
    cursor: pointer;
    display: block;
    padding-left: 22px;
    text-align: center;
    font-size: 1.3rem;
    margin-left: 7px;
    top: 14px;
    @media (min-width: 1026px) {
      top: 25px;
    }

    div {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 11px;
      height: 6px;
      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--bar-bg, #000);
        border-radius: 1px;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

        &:first-of-type {
          top: 0;
        }
        &:last-of-type {
          bottom: 0;
        }
      }
    }

    &:hover {
      color: $or !important;
      div span {
        background: $or !important;
      }
      @media (min-width: 1026px) {
        div span:first-of-type {
          width: 13px;
        }
        div span:last-of-type {
          width: 6px;
        }
      }
    }
  }


  #menu-toggle:checked + #menu {
    display: block;
  }


  @media screen and (min-width: 1026px) {
    padding: 2.5rem 5rem;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.white:not(.fixed) {
    color: $white;
    .icon-menu {
      span {
        background: $white;
      }
    }
    .menu-icon {
      color: $white;
      span {
        background: $white;
      }
    }
    .widget-languages {
      color: $white;
      * {
        color: $white;
      }
      a {
        &:after {
          filter: invert(98%) sepia(98%) saturate(21%) hue-rotate(25deg) brightness(106%) contrast(96%);
        }
      }
    }
    img {
      filter: invert(98%) sepia(98%) saturate(21%) hue-rotate(25deg) brightness(106%) contrast(96%);
    }
  }
  .social-links {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-basis: 91px;
    justify-content: end;
    img {
      transition: all .2s ease-out;
    }
    a {
      &:hover {
        img {
          filter: invert(60%) sepia(78%) saturate(280%) hue-rotate(1deg) brightness(91%) contrast(82%);
        }
      }
    }
  }

  #menu {
    li {
      display: inline-block;
      a {
        color: $white;
        font-size: 12px;
      }
    }
  }


  .menu-label {
      flex-basis: 91px;
  }

  .menu-icon {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;

    label {
      position: absolute;
      margin-left: 29px;
      margin-top: 4px;
      font-size: 1.3rem;
      cursor: pointer;
    }

    .menu-icon__cheeckbox {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
      z-index: 2;
      -webkit-touch-callout: none;
      position: absolute;
      opacity: 0;
    }
    div {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 11px;
      height: 6px;
    }
    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--bar-bg, #000);
      border-radius: 1px;
      transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

      &:first-of-type {
        top: 0;
      }
      &:last-of-type {
        bottom: 0;
      }
    }
    &.active,
    .menu-icon__cheeckbox:checked + div {
      span {
        &:first-of-type {
          transform: rotate(45deg);
          top: 2.5px;
        }
        &:last-of-type {
          transform: rotate(-45deg);
          bottom: 2.5px;
        }
      }
    }

    &.active:hover span:first-of-type,
    &.active:hover span:last-of-type,
    &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
    &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
      width: 11px;
    }

    &:hover {
      color: $or !important;
      span {
        background: $or !important;
      }
      @media (min-width: 1026px) {
        span:first-of-type {
          width: 13px;
        }
        span:last-of-type {
          width: 6px;
        }
      }
    }
  }

  #menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $beige;
    align-items: center;
    justify-content: center;
    display: none;
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: left top;
    overflow: scroll;
    @media screen and (max-width: $width-medium) {
      background-image: url("../img/menu-bg-mobile.svg");
    }
    @media screen and (min-width: 1026px) {
      background-image: url("../img/menu-bg.svg");
      padding-left: 3rem;
      padding-right: 3rem;
    }
   img {
     filter: none !important;
   }
    .main-menu {
      @media screen and (min-width: 1026px) {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1260px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        gap: 1rem;
      }
      .img-menu {
        position: absolute;
        right: 0;
        @media screen and (max-width: $width-large) {
          width: 500px;
          height: auto;
        }
        @media screen and (max-width: $width-medium) {
          display: none;
        }
      }

    }
    #main-menu {
      @media screen and (max-width: $width-medium) {
        margin-left: 3rem;
        margin-right: 3rem;
      }
      .menu {
        display: flex;
        flex-direction: column;
        gap: 3rem;
      }
      .link-all-collections {
        opacity: 0;
        transition: all ease 0.5s;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%; /* 25.5px */
        letter-spacing: 1.5px;
        font-family: $font-t;
        margin-left: 1rem;
        display: inline-block;
        @media screen and (max-width: 1240px) {
          display: none;
        }
        &:hover {
          text-decoration: underline;
        }
        &.active {
          opacity: 1;
        }
      }
      a {
        color: $black;
        font-family: $font-t;
        line-height: 125%;
        position: relative;
        display: inline-block;
        display: flex;
        align-items: baseline;
        @media screen and (min-width: $width-medium) {
          font-size: 3.5rem;
          white-space: nowrap;
        }
        @media screen and (max-width: $width-medium) {
          font-size: 3rem;
        }
        @media screen and (max-width: $width-large) {
          font-size: 3rem;
        }

        span:first-child {
          &:after {
            display: block;
            content: "";
            height: 1px;
            width: 0;
            background: $black;
            transition: all ease 0.5s;
          }
        }

        @media screen and (min-width: $width-medium) {
          &:hover {
            span {
              &:after {
                width: 100%;
              }
            }

            .link-all-collections {
              opacity: 1;
            }

          }
        }


      }
      &:hover {
        a {
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }
    }


    /* MENU DESKTOP */

    @media (min-width: 1026px) {

      .bloc-langues-mobile {
        display: none;
      }
    #main-menu {
      .languages {
        display: none;
      }
    }

    .all-collections {
        background: $beige;
        position: relative;
        opacity: 0;
        transition: opacity ease 0.5s;
        z-index: 100;
      min-height: 600px;
      &.active {
        opacity: 1;
      }
    }
      .menu-collections {
        column-count: 2;
        column-gap: 20px;
      }

      .col {
        break-inside: avoid-column;
        page-break-inside: avoid;
        width: 100%;
        margin-bottom: 2rem;
        position: inherit;
        padding-left: 0;
        padding-right: 0;

        .h5 {
          font-style: italic;
          margin-bottom: 1rem;
          position: relative;
          display: inline-block;

          &:after {
            transition: all ease 0.5s;
            width: 0;
            display: block;
            content: "";
            height: 1px;
            background: $black;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }

        > div {
          border-left: solid 1px $beige_b;
          padding-left: 1.5rem;
        }

        .items {
          display: flex;
          flex-direction: column;
        }

        .miniature-vin {
          position: absolute;
          bottom: 1rem;
          right: 0;
          border: none;
          width: 360px;
          height: 440px;
          text-align: center;
          opacity: 0;
          display: none;
          transition: all ease 0.5s;

          .item-vin {
            height: 270px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2.5rem;
          }

          .sous-titre {
            border: none;
            text-transform: none;
            font-size: 2.2rem;
            font-weight: normal !important;
            margin-bottom: 0;
          }

          .h2 {
            font-family: $font-p;
            font-style: normal;
            letter-spacing: 0;
          }
        }

        .item {
          display: block;
          line-height: 150%; /* 19.5px */

          a {
            padding-top: 4px;
            padding-bottom: 4px;
            display: inline-block;
            @media (max-height: 850px) {
              font-size: 1.2rem;
              padding-top: 0;
              padding-bottom: 0;
            }

            &:hover {
              > span {
                font-weight: 600;
              }


              .miniature-vin {
                opacity: 1;
                display: block;
                z-index: 100;
              }
            }
          }
        }

        &:last-child {
          margin-top: 7rem;

          > div {
            border: none;
            padding-left: 0;
          }

          .img {
            position: relative;

            &:after {
              display: block;
              content: "";
              position: absolute;
              top: 0;
              letter-spacing: 0;
              background-image: url("../img/bg-menu-accords.svg");
              background-repeat: no-repeat;
              background-size: 100% auto;
              background-position: left top;
              width: 100%;
              height: 100%;
              mix-blend-mode: plus-lighter;
              opacity: 0;
              transition: all ease 0.5s;
            }
          }

          .inner {
            margin-top: 3rem;
            text-align: center;

            .h5 {
              margin-bottom: 0;
              font-style: normal;
              transition: all ease 0.5s;
            }

            p {
              font-size: 1.5rem;
              color: $black_p;
            }
          }

          a {
            &:hover {
              .h5 {
                color: $or;
              }

              .img {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .swiper, .swiper-wrapper, .swiper-slide {
        position: inherit !important;
        transform: none !important;
      }
      .swiper-navigation {
        display: none;
      }
    }

    @media (min-width: 1026px) and (max-width: 1300px) {

        .col {
          .item {
            a {
              font-size: 1.3rem;
              padding-top: 2px;
              padding-bottom: 2px;
            }
          }
        }

    }

    @media screen and (min-width: 1026px) and (max-width: 1240px) {
      .all-collections {
        max-width: 670px;
      }
      .col  {
        .miniature-vin {
          bottom: -6rem;
        }
        img {
          max-width: 100%;
          height: auto;
        }

      }
      .menu {
        li {
          display: flex;
          justify-content: space-between;
          .arrow-round {
            transform: rotate(-90deg);
          }
        }
      }
    }

    @media screen and (min-width: 1026px) and (max-width: 1200px) {
      .all-collections {
        max-width: 640px;
      }
    }



    /* MENU MOBILE */
    @media (max-width: $width-medium) {

     .footer-menu {
        margin-left: 3rem;
        margin-right: 3rem;
        padding-top: 2.5rem;
      }

      #main-menu {
        padding-top: 6.5rem;

        .bloc-langues-mobile {
          border-bottom: solid 1px #ddd6cc;
          padding-left: 3rem;
          padding-right: 3rem;
          padding-bottom: 2.5rem;
          margin-left: -3rem;
          margin-right: -3rem;
          margin-bottom: 5rem;
          .wp-block-navigation-item  {
            margin-top: 0 !important;
          }

          img, .wp-block-navigation__submenu-icon {
            display: none;
          }

          .wpml-language-switcher-block .wp-block-navigation__container {
            justify-content: center;
          }

          .wp-block-navigation-submenu, .wpml-ls-slot-langues_area, .wpml-ls-sub-menu {
            display: flex;
            gap: 1rem;
          }
          .current-language-item, .wpml-ls-current-language > a {
            display: inline-block;
            border: solid 1px $black;
            border-radius: 400px;
            padding: 0.5rem 2rem;
            font-style: italic;
            font-size: 18px;
            line-height: 150%;
            background: $black;
            color: $white;
          }

          .wpml-ls-slot-langues_area a {
            display: inline-block;
            border: solid 1px $beige_b;
            border-radius: 400px;
            padding: 0.5rem 2rem;
            font-style: italic;
            font-size: 18px;
            line-height: 150%;
          }

          .wp-block-navigation__submenu-container {
            opacity: 1;
            display: flex;
            justify-content: center;
            gap: 1rem;
            position: relative;
            visibility: visible;
            width: auto;
            height: auto;
            flex-direction: row;
            min-width: inherit;
            li {
              a {
                display: inline-block;
                border: solid 1px $beige_b;
                border-radius: 400px;
                padding: 0.5rem 2rem;
                font-style: italic;
                font-size: 18px;
                line-height: 150%;
              }
            }
          }
        }

        .menu {
          li {
            display: flex;
            justify-content: space-between;
            .arrow-round {
              transform: rotate(-90deg);
            }
          }
        }

      }

      .all-collections {
        position: fixed;
        width: 100%;
        right: -100%;
        transition: all ease 0.5s;
        background: $beige;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 5rem;
        z-index: 200;
        &.active {
          right: 0;
        }
        .col {
          position: relative;
          &:last-child {
            display: none;
          }
        }

        .back {
          .arrow-round {
            transform: rotate(90deg);
          }
        }

        .back-first {
          display: flex;
          padding: 2.5rem 2.2rem;
          justify-content: center;
          border-top: solid 1px $beige_b;
          border-bottom: solid 1px $beige_b;
        }

        .back-second {
          display: flex;
          padding: 2.5rem 2.2rem;
          padding-top: 5rem;
          justify-content: center;
          border-top: solid 1px $beige_b;
        }

        .close-collections {
          font-family: $font-t;
          display: flex;
          align-items: center;
          gap: 2.5rem;
          font-size: 3rem;
          font-style: italic;
          padding-left: 3rem;
          padding-bottom: 3rem;
          padding-top: 2rem;
          color: $black;
        }

        .collection-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 5rem;
          padding-bottom: 3.5rem;
          span {
            font-family: $font-t;
            font-size: 1.8rem;
            line-height: 150%; /* 27px */
            letter-spacing: 0.9px;
            color: $black;
          }
          .logo {
            max-width: 90%;
            width: auto;
          }
        }

        .miniature-vin {
          border: solid 1px $beige_b;
          display: block;
          width: 100%;

          .sous-titre, .h2 {
            text-align: center;
          }

          .vin {
            height: 100%;
            width: auto;
          }
        }

        .item-vin {
          height: auto;
        }

        .see-collection {
            padding-top: 4rem;
            padding-bottom: 5rem;
          border-bottom: solid 1px $beige_b;
          margin-left: -3rem;
          margin-right: -3rem;
          padding-left: 3rem;
          padding-right: 3rem;
        }


        .swiper {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          padding-left: 4.5rem;

          &.count-1 {
            padding-right: 4.5rem;
          }
        }

        .swiper-navigation {
          margin-left: -4.5rem;
        }

        .col {
            &:before {
              display: block;
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: -1rem;
              left: 0;
              z-index: -1;
              background-image: url("../img/menu-bg-collection-mobile.svg");
              background-repeat: no-repeat;
              background-size: auto auto;
              background-position: left top;
            }
        }

      }
    }




    .footer-menu {
      @media screen and (max-width: $width-medium) {
        margin-top: 5rem;
        margin-bottom: 5rem;
      }
      @media screen and (min-width: 1026px) {
        position: absolute;
        bottom: 4rem;
        left: 0;
        right: 0;
      }

      @media (max-height: 900px) {
        bottom: 1rem;
      }


      ul {
        text-align: center;
        list-style: none;
        max-width: 1200px;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        @media screen and (max-width: $width-medium) {
          flex-direction: column;
          gap: 2rem;
          border-top: solid 1px $beige_b;
        }
        li {
          a {
            opacity: 0.6;
            font-size: 1.3rem;
            color: $black;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .bloc-langues {
    @media screen and (max-width: $width-medium) {
      display: none;
    }
  }
  @media screen and (min-width: $width-medium) {
    .widget-languages {
      color: $black;
      font-size: 1.3rem;

      .wpml-ls-flag {
        display: none;
      }

      .current-language-item {
        margin-top: -5px;
      }
    }

    .wpml-ls-sidebars-langues_area {
      margin-top: -5px;
      li {
        list-style: none;
        width: 30px;
      }
      .wpml-ls-sub-menu {
        opacity: 0;
        position: absolute;
      }

      > ul > li:hover {
        .wpml-ls-sub-menu {
          opacity: 1;
        }
      }

      > ul > li > a {
      &:after {
        display: inline-block;
        content: "";
        background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 9" style="enable-background:new 0 0 16 9;" xml:space="preserve"><path id="Vector" class="st0" d="M8.1,9c0.2,0,0.3-0.1,0.4-0.2l7.2-7.2C15.9,1.4,16,1.2,16,1c0-0.2,0-0.5-0.2-0.7C15.7,0.1,15.5,0,15.3,0c-0.2,0-0.4,0.1-0.6,0.2L8,6.9L1.3,0.2C1.2,0.1,0.9,0,0.7,0C0.5,0,0.3,0.1,0.2,0.3C0.1,0.5,0,0.7,0,1c0,0.2,0.1,0.5,0.3,0.6l7.2,7.2C7.6,8.9,7.9,9,8.1,9L8.1,9z"/></svg>');
        width: 8px;
        height: 5px;
        background-repeat: no-repeat;
        margin-left: 5px;
        position: absolute;
        margin-top: 7px;
      }
      }

    }


  }


}

.active-menu-collections-desktop {
  #menu #main-menu .menu {
    a {
      opacity: 0.5;
    }
    .sub-collections {
      opacity: 1;
      a {
        opacity: 1;
      }
    }
  }
}

.active-menu {
  overflow: hidden;
  #header {
    z-index: 200;
    @media screen and (max-width: $width-medium) {
      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 5rem;
        background: $beige;
        z-index: 10;
      }
    }
    #menu {
      display: block;
    }
    .content {
      position: fixed;
      z-index: 20;
      width: calc(100% - 11rem);
      @media (max-width: $width-medium) {
        width: calc(100% - 3rem);
      }
    }
    img {
      filter: invert(10%) sepia(11%) saturate(275%) hue-rotate(289deg) brightness(98%) contrast(86%) !important;
    }
    .menu-icon {
      color: $black !important;
      span {
        background: $black !important;
      }
      &:hover {
        color: $or !important;
        span {
          background: $or !important;
        }
      }
    }

    &.white {
      .widget-languages {
        color: $black;
      }

      #menu-icon {
        color: $black;
        div {
          span {
            background: $black;
          }
        }
      }
    }


  }

  &.active-menu-collections {
    #menu {
      overflow: hidden !important;
    }
  }

  #menu-icon {
    div span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 2.5px !important;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 2.5px !important;
      }
    }

    &:hover {
      div span {
        width: 11px !important;
        top: 2.5px !important;
        bottom: 2.5px !important;
      }
    }

  }


}

.current-language-item {
  display: flex;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
}

.wp-block-navigation .wp-block-navigation__submenu-icon {
  align-self: center;
  background-color: inherit;
  border: none;
  color: currentColor;
  display: inline-block;
  font-size: inherit;
  height: 0.6em;
  line-height: 0;
  margin-left: 0.25em;
  padding: 0;
  width: 0.6em;

  svg {
    display: inline-block;
    height: inherit;
    margin-top: 0.075em;
    width: inherit;
    stroke: currentColor;
  }
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container {
  align-items: normal;
  background-color: inherit;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: opacity .1s linear;
  visibility: hidden;
  width: 0;
  z-index: 2;
}

.wp-block-navigation .wp-block-navigation-item {
  align-items: center;
  display: flex;
  position: relative;
}

.wp-block-navigation .has-child:not(.open-on-click):hover>.wp-block-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto;
}

