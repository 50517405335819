.single-recettes {

  #content {
    overflow: hidden;
  }

  h1 {
    @media screen and (max-width: 500px) {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .match {
    border-top: solid 1px $beige_b;
    .introduction {
      max-width: 725px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding-top: 5rem;
      padding-bottom: 5rem;


      h2 {
        margin-bottom: 1rem;
        @extend .h3;
      }

      @media screen and (max-width: $width-medium) {
        padding-left: $padding;
        padding-right: $padding;
      }

      .see-recipe {
        display: none;
        @media screen and (max-width: $width-small) {
          display: flex;
          margin-top: 5rem;
          justify-content: center;
          gap: 1rem;
          svg {
            margin-top: -2px;
          }
        }
      }
    }

    .end {
      padding-top: 3.5rem;
      padding-bottom: 5rem;
      text-align: center;
      padding-left: 8rem;
      padding-right: 8rem;
      @media screen and (max-width: $width-small) {
        padding-top: 6rem;
      }

      .cta {
        margin-top: 4.5rem;
        display: inline-flex;
        gap: 10px;
        align-items: center;
      }

      .text-link {
        display: none;
        @media screen and (max-width: $width-small) {
          display: block;
        }
      }
    }


    .accords {
      position: relative;
      width: 100%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      .content {
        @media screen and (min-width: $width-small) {
          display: flex;
        }
      }
      .recette {
        aspect-ratio: 1;
        background: white;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        @media screen and (min-width: $width-small) {
          flex-basis: 50%;
          width: 50%;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:after {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: #EFEBE6;
          transition: opacity 0.5s;
        }
        @media screen and (min-width: $width-small) {
          &.vins-hovered {
            &:after {
              z-index: 10;
              opacity: 0.8;
            }
          }
        }

        .hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: transparent;
          border: solid 1px $beige_b;
          transition: all 0.5s;
          * {
            opacity: 0;
            transition: opacity 0.5s;
          }
          .text-link {
            color: $white;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            display: inline-block;
          }
          .see-recipe {
            position: relative;
            z-index: 10;
            svg {
              margin-right: 5px;
              vertical-align: bottom;
              margin-bottom: 5px;
            }

          }
        }
        @media screen and (min-width: $width-small) {
          &:hover {
            .hover {
              background: rgba(43, 40, 41, 0.9);
              border-color: rgba(43, 40, 41, 0.9);

              * {
                opacity: 1;
              }
            }
          }
        }

      }

      .vins {
        aspect-ratio: 1;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @media screen and (min-width: $width-small) {
          flex-basis: 50%;
          width: 50%;
        }

        .swiper {
          width: 100%;
          height: 100%;

          @media screen and (min-width: $width-small) {
            &:hover {
              .hover {
                background: rgba(43, 40, 41, 0.9);
                border-color: rgba(43, 40, 41, 0.9);

                * {
                  opacity: 1;
                }
              }
            }
          }
        }

        .miniature-vin {
          display: flex;
          width: 100%;
          height: 100%;
          .item-vin {
            height: auto;
            padding: 0;
            width: 100%;
            img {
              height: 80%;
              width: auto;
            }
          }
        }

        .hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          padding-top: 135px;
          align-items: center;
          flex-direction: column;
          background: transparent;
          border: solid 1px $beige_b;
          transition: all 0.5s;
          * {
            opacity: 0;
            transition: opacity 0.5s;
          }
          .text-link {
            color: $white;
            margin-top: 45px;
            width: 250px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
        }

        &:after {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: #EFEBE6;
          transition: opacity 0.5s;
        }

        @media screen and (min-width: $width-small) {
          &.recette-hovered {
            &:after {
              opacity: 0.8;
              z-index: 10;
            }
          }
        }



      }


      .container-heart {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 100%;
        display: flex;
        height: 104px;
        width: 104px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        z-index: 10;
        pointer-events: none;

        @media screen and (max-width: $width-small) {
          margin-top: -52px;
          margin-left: 1.5rem;
        }
        @media screen and (min-width: $width-small) {
            left: 50%;
          margin-top: -52px;
          margin-left: -52px;

        }
      }

      .heart {
        display: flex;
        width: 70px;
        height: 70px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        background: $or;
        border-radius: 100%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        transition: opacity 1s;
        position: relative;
        cursor: pointer;
        z-index: 10;
        &:after {
          display: block;
          content: "";
          width: 25px;
          height: 22px;
          background-size: 100% auto;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" fill="none"><path d="M12.4996 22L11.9205 21.6288C11.4336 21.3167 0 13.9119 0 7.23459C0 2.48486 3.44379 0 6.84543 0C9.02008 0 11.0569 0.994586 12.499 2.71336C13.9363 0.997606 15.9762 0 18.1546 0C21.5562 0 25 2.48505 25 7.23459C25 13.9119 13.5661 21.317 13.0795 21.6293L12.4996 22Z" fill="%23FCFAFA"/></svg>')
        }
      }
    }

    .swiper-navigation {
      bottom: -40px;
      top: inherit;
      margin-top: 0;
      position: absolute;
      right: 0;
      display: flex;
      width: auto;
      margin-left: auto;
      height: 0;
      left: 0;
      .swiper-pagination {
        display: block !important;
      }
      @media screen and (max-width: $width-small) {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
      }

      &.count-1 {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
      }

    }

    .accords-vin {
      .recette {
        overflow: visible;
        .swiper-slide {
          overflow: visible;
          @media screen and (min-width: $width-small) {
            &:hover {
              .hover {
                background: rgba(43, 40, 41, 0.9);
                border-color: rgba(43, 40, 41, 0.9);
                opacity: 1;
                * {
                  opacity: 1;
                  z-index: 10;
                }
              }
            }
          }
        }
      }

      .vins {
        @media screen and (min-width: $width-small) {
          &:hover {
            .hover {
              background: rgba(43, 40, 41, 0.9);
              border-color: rgba(43, 40, 41, 0.9);

              * {
                opacity: 1;
                z-index: 10;
              }
            }
          }
        }
      }

    }


  }


}


#recette {
  border-bottom: solid 1px $beige_b;
  border-top: solid 1px $beige_b;
  padding-top: 5rem;
  position: relative;

  &.hidden {
    display: none;
  }


  @media screen and (min-width: $width-medium) {

    &:after {
      display: block;
      content: "";
      height: 100%;
      width: 1px;
      border-left: solid 1px $beige_b;
      position: absolute;
      left: 50%;
      top: 0;
    }

  }

  .container-recette {
    @media screen and (min-width: $width-medium) {
      display: flex;
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
    }
  }


  .infos {
    @media screen and (min-width: $width-medium) {
      width: 50%;
      flex-basis: 50%;
      padding-right: 3rem;
    }
  }

  .content {
    padding: 0 $padding;
  }

  .title {
    display: block;
    text-align: center;
    margin-bottom: 1.5rem;
    @extend .h3;
  }

  .type_recette {
    text-transform: uppercase;
    font-family: $font-t;
    text-align: center;
    display: block;
  }

  .items {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    color: $black_p;
    max-width: 535px;
    margin-left: auto;
    margin-right: auto;
  }

  .picto {
    border: solid 1px $beige_b;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;

    svg {
      height: 45px;
    }
  }

  .item {
    strong, .label {
      display: block;
      text-align: center;
    }

    .label {
      margin-top: 4px;
      font-size: 1.3rem;
    }
  }

  .ingredients {
    margin-top: 3rem;
    padding-bottom: 5rem;

    h3 {
      margin-bottom: 3rem;
      text-align: center;
      font-size: 1.6rem;
      letter-spacing: -0.32px;
      display: block;
      font-family: "phitradesign INK";
    }

    ul {
      color: $black_p;

      li {
        &.strong {
          font-weight: bold;
          margin-top: 1rem;
          &:before {
            display: none;
          }
        }
      }

      @media screen and (min-width: $width-medium) {
        list-style: none;
       /* max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;*/
        li {
          box-sizing: border-box;
        }
      }
    }
  }

  .twist {
    margin-top: 4rem;
  }

  .preparation {
    padding-top: 5rem;
    padding-bottom: 5rem;
    @media screen and (max-width: $width-medium) {
      border-top: solid 1px $beige_b;
    }
    @media screen and (min-width: $width-medium) {
      width: 50%;
      flex-basis: 50%;
      padding-left: 6rem;
    }

    h3 {
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 3rem;
    }

    ol {
      margin-left: 1rem;

      li {
        line-height: 180%;
      }
    }
  }


}