.gutenberg-hero-title {
  position: relative;
  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .sous-titre {
    display: none;
  }

  @media screen and (max-width: $width-medium) {
    padding: 17rem 3rem;
    padding-bottom: 10rem;
    height: auto;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    background-image: url(../img/volute-hero.svg);
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1 ;
    @media screen and (max-width: $width-medium) {
      background-size: 800px auto;
      background-position: -100px top;
      height: 100px;
    }
    @media screen and (min-width: $width-medium) {
      background-size: 100% auto;
      background-position: -1vw top;
      height: 15vw;
    }
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: right bottom;
    right: 0;
    width: 100%;
    z-index: -1 ;
    @media screen and (max-width: $width-medium) {
      background-image: url(../img/volute-hero-title-mobile-complete.svg);
      width: 370px;
      height: 352px;
      bottom: -240px;
      right: 0;
      background-size: 100% 100%;
      max-width: 100%;
      background-position: 80px;
    }

    @media screen and (min-width: $width-medium) {
      background-image: url(../img/volute-hero.svg);
      bottom: -35vw;
      height: 60vw;
      background-size: 100% auto;
    }
  }
  h1, p {
    text-align: center;
    z-index: 20;
    position: relative;
  }

  p, h2.p {
    text-align: center;
    font-family: $font-t;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 0.9px;
    line-height: 150%;
    max-width: 650px;
    margin-top: 0;
    @media screen and (max-width: $width-small) {
      max-width: 315px;
    }
    @media screen and (min-width: $width-medium) {
      font-size: 2.2rem;
      max-width: 850px;
      letter-spacing: 1.1px;
    }
  }

  .bg {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-repeat: no-repeat;
    z-index: 1;
    background-position: center 16rem;
    background-size: auto 200px;
    left: 0;
    @media screen and (min-width: $width-medium) {
      background-size: auto auto;
      background-position: center center;
    }
  }

  .grid {
    width: 100%;
    position: relative;
    z-index: 10;
    height: 200vw;
    @media screen and (min-width: $width-medium) {
      height: 116vw;
    }

    &.grid-11 {
      height: 180vw;
      @media screen and (min-width: $width-medium) {
        height: 113vw;
      }
    }

    &.grid-10 {
      height: 176vw;
      @media screen and (min-width: $width-medium) {
        height: 95vw;
      }
    }

    &.grid-9 {
      height: 170vw;
      @media screen and (min-width: $width-medium) {
        height: 95vw;
      }
    }

    &.grid-8 {
      height: 156vw;
      @media screen and (min-width: $width-medium) {
        height: 79vw;
      }
    }

    &.grid-7 {
      height: 146vw;
      @media screen and (min-width: $width-medium) {
        height: 79vw;
      }
    }

    &.grid-6 {
      height: 146vw;
      @media screen and (min-width: $width-medium) {
        height: 79vw;
      }
    }

    &.grid-6 {
      height: 146vw;
      @media screen and (min-width: $width-medium) {
        height: 65vw;
      }
    }

    &.grid-5 {
      height: 120vw;
      @media screen and (min-width: $width-medium) {
        height: 70vw;
      }
    }

    &.grid-4 {
      height: 100vw;
      @media screen and (min-width: $width-medium) {
        height: 60vw;
      }
    }

    &.grid-3 {
      height: 100vw;
      @media screen and (min-width: $width-medium) {
        height: 60vw;
      }
    }

    &.grid-2 {
      height: 100vw;
      @media screen and (min-width: $width-medium) {
        height: 60vw;
      }
    }

    &.grid-1 {
      height: 100vw;
      @media screen and (min-width: $width-medium) {
        height: 60vw;
      }
    }
  }

  .grid__item {
    position: absolute;
  }

  .grid__item-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    will-change: transform,opacity;
    background-position: 50%;
    background-size: cover;
    position: relative;
  }

  .grid__item__1 {
    top: 80px;
    left: -6rem;
    width: 12vw;
    height: 12vw;
    @media screen and (min-width: $width-medium) {
      left: 5vw;
      top: 8vw;
      width: 11vw;
      height: 11vw;
    }
  }

  .grid__item__2 {
    top: calc(50px + 20vw);
    left: 45%;
    width: 16vw;
    height: 16vw;
    @media screen and (min-width: $width-medium) {
      top: 15vh;
      width: 14vw;
      height: 14vw;
    }
  }
  .grid__item__3 {
    top: 50px;
    right: -6rem;
    width: 20vw;
    height: 20vw;
    @media screen and (min-width: $width-medium) {
      top: 0;
      width: 17vw;
      height: 17vw;
      right: 9vw;
    }
  }
  .grid__item__4 {
    top: calc(80px + 8vw + 22vw);
    left: 0;
    width: 16vw;
    height: 16vw;
    @media screen and (min-width: $width-medium) {
      width: 14vw;
      height: 14vw;
      left: 20%;
      top: 25vw;
    }
    @media screen and (min-width: $width-xlarge) {
      top: 20vw;
    }
  }
  .grid__item__5 {
    width: 10vw;
    height: 10vw;
    right: 10%;
    top: 85vw;
    @media screen and (min-width: $width-medium) {
      top: 28vw;
      width: 12vw;
      height: 12vw;
      right: 18%;
    }
  }
  .grid__item__6 {
    width: 20vw;
    height: 20vw;
    left: 25%;
    top: 100vw;
    @media screen and (min-width: $width-medium) {
      top: 50vw;
      left: 30%;
      width: 17vw;
      height: 17vw;
    }
    @media screen and (min-width: $width-xlarge) {
      top: 38vw;
    }
  }
  .grid__item__7 {
    top: 120vw;
    left: -6vw;
    width: 12vw;
    height: 12vw;
    @media screen and (min-width: $width-medium) {
      left: 5vw;
      width: 11vw;
      height: 11vw;
      top: 55vw;
    }
  }
  .grid__item__8 {
    top: 130vw;
    right: -6rem;
    width: 16vw;
    height: 16vw;
    @media screen and (min-width: $width-medium) {
      right: inherit;
      width: 14vw;
      height: 14vw;
      top: 60vw;
      left: 60%;
    }
  }
  .grid__item__9 {
    top: 140vw;
    right: 17vw;
    width: 20vw;
    height: 20vw;
    @media screen and (min-width: $width-medium) {
      width: 17vw;
      height: 17vw;
      right: 2vw;
      top: 77vw;
    }
  }
  .grid__item__10 {
    top: 160vw;
    left: 14%;
    width: 16vw;
    height: 16vw;
    @media screen and (min-width: $width-medium) {
      width: 18vw;
      height: 18vw;
      left: 20%;
      top: 74vw;
    }
  }
  .grid__item__11 {
    width: 10vw;
    height: 10vw;
    right: 10%;
    top: 170vw;
    @media screen and (min-width: $width-medium) {
      width: 14vw;
      height: 14vw;
      right: 18%;
      top: 99vw;
    }
  }
  .grid__item__12 {
    width: 20vw;
    height: 20vw;
    left: 20%;
    top: 180vw;
    @media screen and (min-width: $width-medium) {
      top: 100vw;
      width: 18vw;
      height: 18vw;
      left: 30%;
    }
  }

  &.portraits {
    height: auto;
    border-bottom: solid 1px $beige_b;
    padding: 0 5.5rem;
    .bg {
      background-position: center 40vw;
      background-size: 50% auto;
      @media screen and (min-width: $width-medium) {
        background-position: center 30vh;
        background-size: auto auto;
      }
    }

    h1 {
      position: fixed;
      top: 60vw;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      z-index: 50;
      @media screen and (min-width: $width-medium) {
        top: 50vh;
        transform: translate(-50%, -50%);

      }
    }

    .bg {
      position: fixed;
      z-index: 40;
    }

  }

  .arrow {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 2vw;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 20;

    &.fixed {
      position: fixed;
    }

  }

}


