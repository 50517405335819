.gutenberg-citation {
  display: flex;
  border-top: solid 1px $beige_b;
  @media screen and (max-width: $width-medium) {
    flex-direction: column;
  }
  @media screen and (min-width: $width-medium) {
    flex-direction: row;
  }

  &.right {
    @media screen and (min-width: $width-medium) {
      flex-direction: row-reverse;
    }
  }

  &.left, &.right {
   .citation {
     .bg {
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       &:after {
         background-image: url(../img/volute-citation-left-2.svg);
         background-repeat: no-repeat;
         background-size: 100% auto;
         display: block;
         content:"";
         width: 100%;
         height: 100%;
         position: absolute;
         background-position: right bottom;
         bottom: 0;
         mix-blend-mode: difference;
         opacity: 0.1;
       }
     }
   }
    .contenu {
      position: relative;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        &:after {
          background-image: url(../img/volute-citation-left-1.svg);
          background-repeat: no-repeat;
          background-size: 30vw auto;
          display: block;
          content:"";
          width: 100%;
          height: 100%;
          position: absolute;
          background-position: left bottom;
          bottom: 0;
          @media screen and (max-width: $width-medium) {
            background-size: 100% auto;
          }
        }
      }

    }
  }

  &.right {
    .citation {
      .bg {
        &:after {
          background-image: url(../img/volute-citation-right-2.svg);
          mix-blend-mode: plus-lighter;
        }
      }
    }
    .contenu {
      .bg {
        &:after {
          background-image: url(../img/volute-citation-right-1.svg);
          background-position: right bottom;
          background-size: 84% auto;
          @media screen and (max-width: $width-medium) {
            background-size: 100% auto;
            background-position: left bottom;
          }
        }
      }
    }
  }

  .citation {
      width: 100%;
    padding: 45px 60px;
    position: relative;
    @media screen and (min-width: $width-medium) {
      width: 30%;
      flex-basis: 30%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &:before {
      display: block;
      content: "";
      height: 1px;
      width: calc(50% - 110px);
      position: absolute;
      left: 0;
      top: 120px;
      background: white;
      @media screen and (min-width: $width-medium) {
        top: 28vw;
      }
    }
    &:after {
      display: block;
      content: "";
      height: 1px;
      width: calc(50% - 110px);
      position: absolute;
      right: 0;
      top: 120px;
      background: white;
      @media screen and (min-width: $width-medium) {
        top: 28vw;
      }
    }
    img {
      width: 180px;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .content {
      color: $white;
      font-size: 3rem;
      font-family: $font-t;
      margin-top: 55px;
      max-width: 255px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-style: italic;

      @media screen and (min-width: $width-medium) {
        font-size: 3.5rem;

      }

    }
  }

  .contenu {
    width: 100%;
    padding: 5rem 3rem;
    @media screen and (min-width: $width-medium) {
      padding-left: 12rem;
      padding-right: 12rem;
      width: 70%;
      flex-basis: 70%;
    }


    h2 {
      text-align: center;
      @media screen and (min-width: $width-medium) {
        margin-bottom: 4rem;
      }
    }

    .subtitle {
      font-family: $font-t;
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      text-align: center;
      display: block;
      margin-bottom: 4.5rem;

      @media screen and (min-width: $width-medium) {
        margin-top: -3rem;
      }
    }
    .swiper {
      margin-left: -3rem;
      margin-right: -3rem;
      margin-bottom: 4rem;
      img {
        width: 100%;
        height: auto;
      }
    }

    .cta {
      margin-top: 4rem;
    }

    p {
      text-align: center;
    }
  }

  .swiper-navigation {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
  }

  .swiper-pagination-bullet-active {
    background: $white !important;
    border-color: $white !important;
  }

}