.gutenberg-vin-assemblage {
  display: flex;
  position: relative;
overflow: hidden;
  @media screen and (max-width: $width-small) {
    flex-direction: column-reverse;
  }
    .image {
      align-self: stretch;
      overflow: hidden;
      @media screen and (min-width: $width-small) {
        width: 30%;
        flex-basis: 30%;
      }
      position: relative;


      img {
        width: 100%;
        display: block;
        height: auto;
      }
      svg {
        display: block;
        height: 572px;
        width: 621px;
        position: absolute;
        right: -90px;
        bottom: 0;
        mix-blend-mode: screen;
        opacity: 0.3;
      }
    }
  .assemblage {
    padding-top: 5rem;
    padding-bottom: 30px;
    position: relative;
    overflow: hidden;
    @media screen and (min-width: $width-small) {
      width: 70%;
      flex-basis: 70%;
    }
    @media screen and (min-width: $width-small) {
      &:after {
        display: block;
        content: "";
        background-image: url(../img/volute-vin-assemblage-v2-2.svg);
        background-size: 100% 100%;
        height: 572px;
        width: 621px;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0.6;
      }
    }

    .content {

      display: flex;

      @media screen and (max-width: $width-small) {
        flex-direction: column;
        padding: 5rem 3rem;
        gap: 0rem;
        padding-bottom: 2rem;
      }
      @media screen and (min-width: $width-small) {
        padding: 6rem;
        gap: 10rem;
      }
      .label {
        @extend .h5;
        font-style: italic;
        display: block;
        margin-bottom: 0.5rem;
      }

      .item {
        margin-bottom: 3.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }

      .cepages {
        width: 60%;
        flex-basis: 60%;
        .item {
          .legend {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%; /* 25.5px */
            margin-top: 0;
            display: block;
          }
          &.sub {
            width: 400px;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
        }

        .raisin {
          position: relative;
          width: 170px;
          height: 43px;
          .one {
            background-image: url(../img/raisins.svg);
            width: 170px;
            height: 43px;
            background-size: 170px 43px;
            display: block;
          }
          .two {
            background-image: url(../img/raisins-rouge.svg);
            width: 100%;
            height: 43px;
            background-size: 170px 43px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }




      }
      .autres {
        @media screen and (max-width: $width-small) {
          margin-top: 3.5rem;
        }
        @media screen and (min-width: $width-small) {
          width: 40%;
          flex-basis: 40%;
        }
        .item {
          .value {
            font-size: 2.2rem;
            font-family: $font-t;
            line-height: 150%;
            span {
              font-size: 3.5rem;
              line-height: 125%;
            }
          }
        }
      }
    }

    h2 {
      @extend .h3;
      display: block;
      border-bottom: solid 1px $beige_b;
      text-align: center;
      padding-bottom: 2rem;
      padding-left: 6rem;
      padding-right: 6rem;
      margin-bottom: 0;
    }

  }



}

.color-rose, .color-rose-zh-hans, .color-rose-en {
  .gutenberg-vin-assemblage {
   .raisin {
     .two {
       filter: invert(22%) sepia(80%) saturate(1980%) hue-rotate(319deg) brightness(98%) contrast(101%);
     }
   }
  }
}

.color-rouge, .color-rouge-zh-hans, .color-red {
  .gutenberg-vin-assemblage {
    .raisin {
      .two {
        filter: invert(18%) sepia(70%) saturate(1954%) hue-rotate(345deg) brightness(107%) contrast(89%);
      }
    }
  }
}

.color-blanc, .color-blanc-zh-hans, .color-white {
  .gutenberg-vin-assemblage {
    .raisin {
      .two {
        filter: invert(81%) sepia(6%) saturate(3249%) hue-rotate(357deg) brightness(77%) contrast(90%);
      }
    }
  }
}


