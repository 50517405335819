.hero-accords {
  h1 {
    @media screen and (max-width: 500px) {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .gutenberg-hero-title {
    height: auto;
    padding-top: 220px;
    padding-bottom: 100px;



    @media screen and (max-width: $width-small) {
      padding-top: 115px;
      padding-bottom: 50px;
      overflow: visible;
    }

    .photo-1 {
      display: none;
      @media screen and (min-width: $width-small) {
        mix-blend-mode: overlay;
        position: absolute;
        right: 120px;
        top: 0;
        display: block;
      }
    }

    .photo-2 {
      display: none;
      width: 239px;
      @media screen and (min-width: $width-small) {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
      }
      @media screen and (min-width: $width-small) and (max-width: 920px) {
        width: 20vw;
        height: auto;
        bottom: 6vw;
      }
    }
  }
}


.post-type-archive, .page-template-page-accords {

  #content {
    overflow: hidden;
  }

  #footer {
    @media screen and (max-width: $width-small) {
      border-top: solid 1px $beige_b;
    }
  }

/*
  .content-slider-recipes {
    display: none;
    &.active {
      display: block;
    }
  }
*/

  .video-container {
    margin-top: 5rem;
    width: 720px;
    height: 100vw;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    max-width: 100%;
    @media screen and (min-width: $width-small) {
      height: 720px;
    }

    video {
      width: 100%;
    }
  }

  .accords-filters {
    border-top: solid 1px $beige_b;
    z-index: 10;
    position: relative;
    #ajax-filter-adjectif {
      display: none;
      &.active {
        display: block;
        min-width: 320px;
      }
      @media screen and (min-width: $width-small) {
        display: block;
      }
    }

    .accords-sub-filters {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      flex-wrap: wrap;
      @media screen and (max-width: $width-small) {
        justify-content: center;
      }
      @media screen and (min-width: $width-small) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }



      ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        max-width: 370px;
        flex-basis: 370px;
        row-gap: 1.5rem;
        @media screen and (max-width: $width-small) {
          max-width: 316px;
          flex-basis: 316px;
        }
        li {
          width: 50%;
          line-height: 1 !important;
          font-size: 1.3rem;

          input.strikethrough + label {
            text-decoration: line-through;
          }

        }
      }

      label {
        padding-left: 8px;
        cursor: pointer;
      }

      input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid $beige_b;
        background-color: #fff;
        cursor: pointer;
        border-radius: 2px;

        &::before {
          content: '\2713';
          display: inline-block;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          color: transparent;
        }


      }

      input[type="checkbox"]:checked {
        background-color: $or;
        borde-color: $or;
        color: $white;

        &::before {
          color: $white;
        }
      }

      &#ajax-filter-vin {
        display: none;
        &.active {
          display: block;
          display:flex;
          gap: 1rem;
          row-gap: 2rem;
        }
        @media screen and (min-width: $width-small) {
          display: block;
        }

        @media screen and (max-width: $width-large) {
          padding: $padding;
        }

        .grid {
          @media screen and (max-width: $width-large) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, auto);
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;
          }
          @media screen and (min-width: $width-large) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 5rem;
            grid-row-gap: 2rem;
            align-items: flex-start;
            max-width: 600px;
          }
        }

        ul {
          li {
            width: 100%;
            white-space: nowrap;
            &.count-0 {
              opacity: 0.5;
              pointer-events: none;
              order: 100;
            }
          }
        }

        #ajax-filter-collection {
          @media screen and (min-width: $width-large) {
            grid-area: 1 / 1 / 3 / 2;
          }
          @media screen and (max-width: $width-large) {
            grid-area: 1 / 1 / 2 / 2;
          }
          ul {
            flex-direction: column;
          }

        }
        #ajax-filter-caracteristiques {
          @media screen and (min-width: $width-large) {
            grid-area: 1 / 2 / 3 / 3;
          }
          @media screen and (max-width: $width-large) {
            grid-area: 1 / 2 / 2 / 3;
          }
          ul {
            flex-direction: column;
          }
        }

        #ajax-filter-dominance {
          @media screen and (min-width: $width-large) {
            grid-area: 1 / 3 / 2 / 4;
            padding-right: 2rem;
          }
          @media screen and (max-width: $width-large) {
            grid-area: 2 / 1 / 3 / 3;
            margin-top: 2rem;
          }
        }

        #ajax-filter-appellation {
          @media screen and (min-width: $width-large) {
            grid-area: 2 / 3 / 3 / 4;
            padding-right: 2rem;
          }
          @media screen and (max-width: $width-large) {
            grid-area: 3 / 1 / 4 / 3;
          }
        }

        select {
          -webkit-appearance: none;
          -moz-appearance:    none;
          appearance:         none;
          box-shadow: none;
          font-size: 1.5rem;
          background: transparent;
          border: solid 1px #ddd6cc;
          border-radius: 400px;
          padding: 8px 20px;
          max-width: 100%;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63545 5.53553L1.6857 0.585786L0.271484 2L6.63545 8.36396L12.9994 2L11.5852 0.585786L6.63545 5.53553Z" fill="%23414141"/></svg>');
          background-size: 13px 9px;
          background-position: calc(100% - 2rem) center;
          background-repeat: no-repeat;
          padding-right: 5rem;
          width: 220px;
          @media screen and (max-width: $width-small) {
            width: 100%;
          }
        }
      }
    }

    .sub-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px $beige_b;
      margin-bottom: 0;

      @media screen and (max-width: $width-small) {
        flex-direction: column;
        padding-top: 1.5rem;
      }


      .label {
        @media screen and (min-width: $width-small) {
          width: 37%;
          flex-basis: 37%;
        }
        @media screen and (min-width: $width-medium) {
          width: 50%;
          flex-basis: 50%;
        }
      }

      .accords-sub-filters {
        @media screen and (min-width: $width-small) {
          width: 63%;
          flex-basis: 63%;
        }
        @media screen and (min-width: $width-medium) {
          width: 50%;
          flex-basis: 50%;
        }
      }

      .label {
        @media screen and (min-width: $width-small) {
          text-align: right;
          padding-right: 2rem;
        }
        @extend .h5;
      }

      &.reinit {
        display: none;

        &.active {
          display: flex;
        }

        .label {
          font-family: $font-p;
          font-size: 1.3rem;
          letter-spacing: 0;
          line-height: 150%;
          @media screen and (max-width: $width-small) {
            display: none;
          }

          a {
            display: flex;
            justify-content: end;
            color: $black_p;
          }

          svg {
            margin-right: 4px;
          }
        }

        .label-mobile {
          display: none;

          @media screen and (max-width: $width-small) {
            display: flex;
          }
          text-decoration: none;
          align-items: center;
          width: 100%;
          justify-content: center;

          height: 28px;
          padding-bottom: 1.5rem;
          svg {
            margin-right: 0;
            transition: all 0.5s;
          }
          &.active {
            border-bottom: solid 1px $beige_b;
            svg {
              transform: rotate(180deg);
            }
          }
          .count {
            margin-left: 4px;
          }
        }
      }


      .accords-sub-filters {
        @media screen and (min-width: $width-small) {
          padding-left: 2rem;
          border-left: solid 1px $beige_b;
        }
      }

      .label-radio {
        display: inline-block;
        cursor: pointer;
        display: flex;
        padding: 7px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 400px;
        border: solid 1px $beige_b;
        font-family: $font-t;
        font-size: 1.8rem;
        font-style: italic;
        text-align: center;
        opacity: 1;
        color: $black;
        @media screen and (min-width: $width-small) {
          font-size: 2.2rem;
          padding: 9px 30px;
        }

        &.rose, &.rouge, &.blanc, &.rose-en, &.red, &.white, &.rose-zh-hans, &.rouge-zh-hans, &.blanc-zh-hans {
          &:before {
            display: inline-block;
            content: "";
            width: 15px;
            height: 15px;
            background: $rouge;
            border-radius: 100%;
            margin-right: 10px;
          }
        }

        &.rose, &.rose-en, &.rose-zh-hans {
          &:before {
            background: $rose;
          }
        }

        &.blanc, &.white, &.blanc-zh-hans {
          &:before {
            background: $aurore;
          }
        }

        &:hover {
          background: $black;
          border-color: $black;
          color: white;
          &:before {
            border: solid 1px $white;
          }
        }

      }

      input[type=radio] {
        display: none;

        &:checked + .label-radio {
          background: $black;
          border-color: $black;
          color: white;
          &:before {
            border: solid 1px $white;
          }
        }
      }

    }


    form {
      &.disabled {
        opacity: 0.5;
        @media screen and (max-width: $width-small) {
          display: none;
        }

        label, input {
          pointer-events: none;
        }
      }
    }
  }


  #slider-recettes {
    position: relative;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -50px;
      opacity: 0;
      z-index: -1;
      background: $beige;
      transition: all 0.1s;
    }

    &:after {
      transition: all 0.1s;
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      margin-left: -20px;
      margin-top: -20px;
      background-image: url(../img/loading.gif);
      opacity: 0;
      z-index: -1;
    }

    &.loading {
      &:before, &:after {
        opacity: 1;
        z-index: 100;
      }
    }
  }






  li {
    &:before {
      display: none !important;
    }
  }

  .content-dynamic {
    padding-left: $padding;
    padding-right: $padding;
    text-align: center;
    display: none;
    @media screen and (min-width: $width-small) {
      margin-bottom: 3rem;
    }

    .heart {
      display: flex;
      width: 70px;
      height: 70px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      background: $or;
      border-radius: 100%;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      transition: opacity 1s;
      position: relative;
      cursor: pointer;
      margin-top: -35px;
      z-index: 10;
      margin-bottom: 2rem;


      &:after {
        display: block;
        content: "";
        width: 25px;
        height: 22px;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" fill="none"><path d="M12.4996 22L11.9205 21.6288C11.4336 21.3167 0 13.9119 0 7.23459C0 2.48486 3.44379 0 6.84543 0C9.02008 0 11.0569 0.994586 12.499 2.71336C13.9363 0.997606 15.9762 0 18.1546 0C21.5562 0 25 2.48505 25 7.23459C25 13.9119 13.5661 21.317 13.0795 21.6293L12.4996 22Z" fill="%23FCFAFA"/></svg>');
        background-size: 100% auto;
      }

      .heart-anim {
        opacity: 0;
        position: absolute;
        margin-top: -4px;
      }

      &:hover {
        .heart-anim {
          opacity: 1;
        }
      }
    }

    &.active {
      display: block;
    }

    h2 {
      margin-top: 1rem;
    }

    .collection {
      font-family: $font-t;
      text-transform: uppercase;
      margin-bottom: 1rem;
      display: block;
    }

    .content-reinit {
      display: none;

      @media screen and (max-width: $width-small) {
        display: block;
        padding-top: 3rem;
        padding-bottom: 3rem;
        margin-top: 3rem;
        border-top: solid 1px $beige_b;
        margin-left: -3rem;
        margin-right: -3rem;
      }

      a {
        display: flex;
        justify-content: center;
        gap: 5px;
        font-size: 1.3rem;
        color: $black_p;
      }
    }
  }

  .swiper-slide {
    .hover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $or-t;
      opacity: 0;
      transition: opacity 1s;

      p {
        max-width: 300px;
        display: block;
        color: $white;
        font-family: $font-t;
        font-size: 2.2rem;
      }
    }
  }

  .swiper-slide-active {
    header {
      &:hover {
        .hover {
          opacity: 1;
        }
      }
    }
  }

  h2 {
    font-size: 3rem;
    text-align: center;
    margin-top: 60px;
    @media screen and (min-width: $width-small) {
      font-size: 3.5rem;
    }
  }

  .swiper-cards {
    .swiper-navigation {
      position: absolute;
      width: 272px;
      height: 20px;
      margin-top: 0;
      left: 50%;
      margin-left: -136px;
      height: 50px;
      z-index: 10;
      display: none;
      bottom: -24px;


      .swiper-button-next, .swiper-button-prev {
        width: 50px;
        height: 50px;
        background: $white;
        border-radius: 100%;
        display: flex;

        &:after {
          margin-top: 0;
        }
      }

      .swiper-button-next {
        right: 0;
        position: absolute;
        margin-top: 0;
        top: 0;
      }

      .swiper-button-prev {
        left: 0;
        position: absolute;
        margin-top: 0;
        top: 0;
      }

      &.active {
        display: block;
      }
    }
  }



  .swiper-recipes {
    margin-left: 3rem;
    margin-right: 3rem;
    .swiper-wrapper {
      width: 312px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (min-width: $width-small) {
        width: 600px;
      }
    }

    .swiper-slide {
      opacity: 0;
      width: 312px;
      @media screen and (max-width: $width-small) {
        max-width: 600px;
      }

      header {
        background: #eee;
        height: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        position: relative;

        img {
          position: absolute;
          width: 100%;
          object-fit: cover;
          height: auto;
          top: 0;
        }
      }
    }

    article {
      transition-property: all;
      transition-duration: 1s;
      width: 312px;

      @media screen and (min-width: $width-small) {
        width: 600px;
      }

      &:after, &:before {
        display: block;
        content: "";
        width: 124px;
        height: 100%;
        position: absolute;
        top: 0;
        opacity: 0;
        transition: opacity 1s;
        transition-delay: .5s;
      }

      &:after {
        right: -124px;
        background: linear-gradient(95deg, rgba(239, 235, 230, 0.85) 3.78%, transparent 70%);
      }

      &:before {
        left: -124px;
        background: linear-gradient(95deg, rgba(239, 235, 230, 0.85) 3.78%, transparent 70%);
        transform: rotate(-180deg);
      }
    }

    .swiper-slide-next, .swiper-slide-prev {
      filter: blur(4px);

      article {
        scale: 0.6;
        margin-left: 100px;
        transform: rotate(15deg);
        @media screen and (min-width: $width-small) {
          scale: 0.6;
          margin-left: 200px;
          transform: rotate(10deg);
        }
      }
    }

    .swiper-slide-prev {
      article {
        margin-left: -100px;
        transform: rotate(-15deg);
        @media screen and (min-width: $width-small) {
          margin-left: -200px;
          transform: rotate(-10deg);
        }
      }
    }

    .swiper-slide {
      article {
        scale: 0.6;
      }
    }

    .swiper-slide-active {
      article {
        scale: 1;

        &:after, &:before {
          opacity: 1;
        }
      }

      .heart {
        opacity: 1;
      }
    }

    .swiper-slide-active, .swiper-slide-next, .swiper-slide-prev {
      opacity: 1;
      overflow: visible;
    }

    .swiper-slide-shadow {
      opacity: 0 !important;
    }
  }


  .content-slider-recipes {
    &.active {
      margin-top: 5rem;
      .swiper-navigation {
        display: block;
      }
    }
  }

  #slider-vins {
    header {
      display: flex;
      align-items: center;
      background: $beige;
      border: solid 1px $beige_b;
    }
    .vin {
      height: 80%;
      position: relative;
      object-fit: inherit;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }


}
