.error404 {
#content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $padding;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10rem;
  h1 {
    text-align: center;
  }
  @media screen and (max-width: $width-small) {
    margin-bottom: 3rem;
    padding-top: 12rem;
  }
  @media screen and (min-width: $width-small) {
    padding-top: 0;
    min-height: calc(100vh - 275px);
  }
  .cta {
    margin-top: 3rem;
  }
}
  #breadcrumb {
    left: 0;
  }
}