.gutenberg-key {
  text-align: center;
  padding: 4.5rem $padding;
  padding-bottom: 5rem;
  border-bottom: solid 1px $beige_b;
  position: relative;
  @media screen and (min-width: $width-medium) {
    &:after {
      display: block;
      content: "";
      position: absolute;
      background-image: url("../img/bg-keys.svg");
      background-repeat: no-repeat;
      background-size: 38% auto;
      background-position: right top;
      top: 0;
      left: 0;
      width: 100%;
      height: 55vw;
      z-index: -1;
    }
  }
  .content {
    margin-top: 4.5rem;
    border-top: solid 1px $beige_b;
    margin-left: -3rem;
    margin-right: -3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;
    @media screen and (min-width: $width-medium) {
      display: flex;
      justify-content: center;
      gap: 50px;
      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 180px;
        width: 100%;
        height: 1px;
        background: $beige_b;
      }
    }
    @media screen and (min-width: $width-large) {
      gap: 115px;
    }
  }
  .img {
    border: solid 1px $beige_b;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    img {
      display: block;
    }
  }
  .number {
    font-size: 5rem;
    font-family: $font-t;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .label {
    text-transform: uppercase;
    font-family: $font-t;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .description {
    font-family: $font-t;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .item {
    @media screen and (max-width: $width-medium) {
      margin-bottom: 3.5rem;
    }
    @media screen and (min-width: $width-medium) {
      width: 235px;
    }
  }

}