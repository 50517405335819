.gutenberg-date {
  border-bottom: solid 1px $beige_b;
  position: relative;
  h2 {
    @extend .h3;
  }

  @media screen and (min-width: $width-medium) {
      border-top: solid 1px $beige_b;
  }
  &:after {
    @media screen and (min-width: $width-medium) {
      display: block;
      content: "";
      z-index: 10;
      position: absolute;
      background-image: url("../img/filet-desktop.svg");
      width: 50px;
      height: 100%;
      top: 0;
      left: 50%;
      margin-left: -25px;
      background-repeat: repeat;
      background-position: center top;
      background-size: 100% auto;
    }
  }
  .swiper-navigation {
    position: absolute;
    z-index: 20;
    top: 108vw;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    justify-content: space-between;
    @media screen and (min-width: $width-medium) {
      display: none;
    }
  }
  .swiper-slide {
    background: $beige;
    .container-photo {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      background-color: $beige;
      .img {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
      }
      .bg {
        mix-blend-mode: overlay;
        width: 100%;
        height: auto;
      }
    }

    .date {
      color: $white;
      font-size: 3rem;
      font-style: italic;
      font-family: $font-t;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 233px;
      width: 236px;
      margin-left: auto;
      margin-right: auto;
      background-image: url("../img/feuille.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @media screen and (min-width: $width-medium) {
        background-image: none;
      }
      @media screen and (max-width: $width-medium) {
        margin-top: -10rem;
        z-index: 20;
        position: relative;
      }

      span {
        @media screen and (max-width: $width-medium) {
          max-width: 130px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }

    }
    .content {
      padding: $padding;
      padding-top: 0;
      @media screen and (min-width: $width-medium) {
        padding: 6rem 12rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      h2 {
        margin-bottom: 1rem;
      }
        .sub-title {
          font-family: $font-t;
          text-transform: uppercase;
          font-size: 1.5rem;
          margin-bottom: 3rem;
          display: block;
          letter-spacing: 1.5px;
        }
      p {
        margin-bottom: 1.5rem;
      }


      &:after {
        @media screen and (max-width: $width-medium) {
          display: block;
          content: "";
          background-image: url("../img/filet.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 200px;
          position: absolute;
          top: 93vw;
          left: 0;
          z-index: 10;
          background-position: center top;
          background-size: 100% auto;
        }
      }


    }
  }
  .swiper {
    @media screen and (min-width: $width-medium) {
      overflow: inherit;
    }
  }

  .swiper-slide {
    &:first-child {
    .content {
      background-image: url("../img/bg-date.svg");
      background-repeat: no-repeat;
      background-size: 375px auto;
      background-position: left bottom;
      @media screen and (min-width: $width-medium) {
        background-size: 100% auto;
      }
    }
    }
  }

  .swiper-wrapper {
    @media screen and (min-width: $width-medium) {
      display: block;
      .swiper-slide {
        display: flex;
        justify-content: start;
        &:nth-child(odd){
          flex-direction: row-reverse;
        }
        .image, .content {
          width: 50%;
          flex-basis: 50%;
          align-self: stretch;
        }
        .date {
          display: none;
        }

        > .sticky-contents {
          height: 100%;
        }
      }
    }
  }

  .desktop-feuille {
    position: absolute;
    top: 0;
    width: 236px;
    height: 100%;
    z-index: 20;
    left: 50%;
    margin-left: -118px;
    .sticky-contents {
      top: 100px;
    }
    #dateFeuille {
      height: 233px;
      width: 236px;
      background-image: url("../img/feuille.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 3.5rem;
      font-style: italic;
      color: $white;
      font-family: $font-t;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 55px;
      text-align: center;
    }
    @media screen and (max-width: $width-medium) {
      display: none;
    }
  }
}