@import "init/_mixins";

body {
  background: $beige;
  overflow-x: hidden;
  &.fixed {
    position: fixed;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#container {
  ul {
    list-style: none;
    li {
      line-height: 170%;
      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: 5px;
        background: $black_p;
        border-radius: 100%;
        margin-right: 10px;
      }
    }
  }
}


// Swiper

.swiper {
  &.delete-nav {
    .swiper-navigation {
      display: none;
    }
  }
  &.loop {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  position: relative;
  width: auto;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  vertical-align: middle;
}

.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  border: solid 1px $beige_b;
  background: none;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: $black !important;
  border-color: $black !important;
}

.swiper-navigation {
  text-align: center;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  display: inline-block;
  height: auto;
  vertical-align: middle;
  width: auto;
margin-top: -1rem;
  @include arrow-after;

}

.swiper-button-prev {
  &:after {
    transform: rotate(-180deg);
  }
}

.hidden-fr {
  display: none;
}


.border-top {
  border-top: solid 1px $beige_b;
}

.border-bottom {
  border-bottom: solid 1px $beige_b;
}

.margin-top {
  margin-top: 2rem;
}