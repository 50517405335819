.gutenberg-actualite {
  border-top: solid 1px $beige_b;

  h2 {
    @extend .h3;
  }

  .texts {
    background-image: url("../img/volute-actu.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left bottom;
  }
  .images {
    background: #333;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    aspect-ratio: 1;
  }

  .swiper-actualite {
    .date {
      margin-bottom: 2.5rem;
    }
  }

}