@font-face {
  font-family:"ivymode";
  src:url("https://use.typekit.net/af/2b42d0/00000000000000007735ce7b/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),
  url("https://use.typekit.net/af/2b42d0/00000000000000007735ce7b/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),
  url("https://use.typekit.net/af/2b42d0/00000000000000007735ce7b/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face{
  font-family: "phitradesign INK";
  src: url("../fonts/phitradesign.eot");
  src: url("../fonts/phitradesign.eot?#iefix")format("embedded-opentype"),
  url("../fonts/phitradesign.woff")format("woff"),
  url("../fonts/phitradesign.woff2")format("woff2"),
  url("../fonts/phitradesign.ttf")format("truetype"),
  url("../fonts/phitradesign.svg#phitradesign INK")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


$font-t: "ivymode", Times, sans-serif;
$font-p: 'Inter', sans-serif;

html {
  font-size: 62.5%;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

body, p {
  font-family: $font-p;
  font-size: 1.5rem;
}

a {
  color: $black;
  text-decoration: none;
  transition: all 0.2s ease-out;
}


  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5 {
    font-family: $font-t;
    font-weight: 400;
    font-style: normal;
    margin-top: 0;
    color: $black;
  }

p, .p {
  margin-top: 0;
  line-height: 170%;
  color: $black_p;
  font-family: $font_p;
  font-size: 1.5rem;
  letter-spacing: 0;
}

h1, .h1 {
  font-size: 5rem;
  margin-bottom: 2.5rem;
  line-height: 105%;
  @media screen and (min-width: $width-medium) {
    font-size: 7rem;
  }
}

h2, .h2 {
  font-size: 4rem;
  margin-bottom: 3rem;
  line-height: 105%;
  @media screen and (min-width: $width-medium) {
    font-size: 6rem;
    margin-bottom: 3rem;
  }
}

h3, .h3 {
  font-size: 3.5rem;
  line-height: 110%;
  @media screen and (min-width: $width-medium) {
    font-size: 5rem;
  }
}

h4, .h4 {
  font-size: 3rem;
  line-height: 125%;
  @media screen and (min-width: $width-medium) {
    font-size: 3.5rem;
  }
}

h5, .h5 {
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.9px;
  @media screen and (min-width: $width-medium) {
    font-size: 2.2rem;
    letter-spacing: 1.1px;
  }
}