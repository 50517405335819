.gutenberg-title-text {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .content {
        max-width: 1250px;
        margin-left: auto;
        margin-right: auto;
        padding-left: $padding;
        padding-right: $padding;
        .title {
            margin-bottom: 2.5rem;
        }
    }
}