.popin {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 1000;
  opacity: 0; /* Opacité initiale à 0 */
  transition: all 0.3s ease-in-out;
  .cta {
    border: none;
  }

}

.popin-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $beige;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  max-width: 80%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  background-image: url(../img/volute.svg);
  background-repeat: no-repeat;
  background-size: auto 150%;
  background-position: left bottom;

  p {
    font-family: $font-t;
    font-size: 1.8rem;
    text-align: center;
  }

  div {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cta, .cta-second {
    border: none;
    cursor: pointer;
  }
}

.popin.show {
  opacity: 1;
}


.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}