.page-id-11, .page-id-2509, .page-id-1568 {
  #content {
    article {
      .entry-content {
        > div, > section {
          background-color: $beige;
          z-index: 10;
          position: relative;
          &.gutenberg-hero-title {
            z-index: 0;
          }
        }
      }
    }
    .gutenberg-redirection {
      position: relative;
      z-index: 10;
      background-color: $beige;
    }
  }
  footer {
    position: relative;
    z-index: 10;
    background-color: $beige;
  }
}