.gutenberg-redirection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
  border-bottom: solid 1px $beige_b;
  @media screen and (min-width: $width-xsmall) {
    padding: 19rem 0;
    height: 40vw;
  }

  img {
    position: absolute;
    height: auto;
  }

  .img-1 {
    bottom: 0;
    left: 0;
    width: 35%;
    @media screen and (min-width: $width-xsmall) {
      height: 60%;
      width: auto;
    }
  }

  .img-2 {
    top: 0;
    left: 20vw;
    width: 30%;
    @media screen and (min-width: $width-xsmall) {
      height: 55%;
      width: auto;
    }
  }

  .img-3 {
    top: 0;
    right: 0;
    width: 30%;
    @media screen and (min-width: $width-xsmall) {
      height: 100%;
      width: auto;
    }
  }

  .content {
    background: $or_t;
    z-index: 10;
    color: $white;
    padding: 40px 25px;
    text-align: center;
    position: relative;
    margin-left: 4.5rem;
    margin-right: 4.5rem;
    max-width: 580px;
    @media screen and (min-width: $width-xsmall) {
      padding: 4rem 6rem;
    }

    h2 {
      margin-bottom: 0;
      color: $white;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
      letter-spacing: 1.5px;
      line-height: 170%;
      color: $white;
    }

    &.color-jaune {
      background: $yellow_t;
      color: $black;
      h2, h3 {
        color: $black;
      }
    }

    &.color-rouge {
      background: $rouge;
      color: $white;
      h2, h3 {
        color: $white;
      }
    }
  }

  &.reverse {
    .img-1 {
      top: 0;
      left: 0;
      width: 46%;
      @media screen and (min-width: $width-xsmall) {
        height: 100%;
        width: auto;
        top: inherit;
        bottom: 0;
      }
    }

    .img-2 {
      bottom: 0;
      right: 5vw;
      left: inherit;
      width: 53%;
      top: inherit;
      z-index: 5;
      @media screen and (min-width: $width-xsmall) {
        height: 55%;
        width: auto;
        right: 10vw;
      }
    }

    .img-3 {
      top: 40%;
      right: 0;
      width: 29%;
      @media screen and (min-width: $width-xsmall) {
        height: 70%;
        width: auto;
        top: 0;
      }
    }
  }
}