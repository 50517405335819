.gutenberg-hero {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;
  position: relative;
  width: 100%;
  overflow: hidden;
  &.photo {
    height: 100vh;
    padding: $padding;
    &:after {
      display: block;
      content: "";
      background-image: url("../img/volute-2.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      background-size: 100% auto;
      background-position: right bottom;
      position: absolute;
      bottom: 0;
      mix-blend-mode: soft-light;
      right: -30%;
      @media screen and (min-width: $width-small) {
        background-size: auto 100%;
        right: 0;
        background-position: calc(100% + 10px) bottom;
      }
    }
  }

  &.video {
    height: auto;
    flex-direction: row;

  }




  p {
    font-family: $font-t;
    font-size: 1.8rem;
    display: block;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    line-height: 150%;
    letter-spacing: 0.9px;
    color: $white;
    @media screen and (min-width: $width-medium) {
      font-size: 2.2rem;
      letter-spacing: 1.1px;
    }
  }

  .arrow {
    position: absolute;
    bottom: 140px;
    left: 50%;
    margin-left: -6px;
    cursor: pointer;
    z-index: 20;
    @media screen and (min-width: $width-small) {
      bottom: 50px;
    }
  }

  .video-container {
    max-width: 100%;
    overflow: hidden;
  }

  video {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    @media screen and (max-width: $width-small) {
      height: 70vh;
    }
  }

  &.white {
    .arrow {
      filter: invert(98%) sepia(98%) saturate(21%) hue-rotate(25deg) brightness(106%) contrast(96%);
    }
    h1, h2, h3 {
      color: $white;
    }
  }

}

.home {
  .gutenberg-hero {
    .arrow {
      bottom: 20px;
    }
  }
}