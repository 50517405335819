#header {
  &.white {
    #menu-icon div span {
      background: $white;
    }
    &.fixed {
      #menu-icon div span {
        background: $black;
      }
    }
  }
}

#breadcrumb {
  &.white {
    color: $white;
  }
}

.gutenberg-slider-video {
  width: 100%;
  overflow: hidden;

  .logo-slider-video {
    position: absolute;
    top: 45%;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease 0.5s;
    opacity: 0;
    pointer-events: none;
    @media screen and (max-width: $width-small) {
      width: 120px;
      height: auto;
      top: 40%;
    }
  }

  .swiper-video {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;

    .swiper-wrapper {
      .swiper-slide {
        video {
          width: 100%;
          height: 100%;
          @media screen and (max-width: $width-medium) {
            height: 70vh;
            object-fit: cover;
          }
        }

        img {
          width: 100%;
          height: auto;
          @media screen and (max-width: $width-medium) {
            height: 70vh;
            object-fit: cover;
          }
        }

      }
    }

    .content {
      position: absolute;
      top: 50%;
      color: $black;
      left: 100px;
      z-index: 10;
      transition: all ease 0.5s;
      height: 150px;
      transform: translateY(-50%);
      opacity: 0;
      @media screen and (max-width: $width-medium) {
        width: auto;
        left: 3rem;
        top: inherit;
        transform: inherit;
        bottom: 100px;
      }
      .h4 {
        margin-bottom: 2rem;
        display: block;
      }
      .h4, a {
        color: $black;
        transition: all ease 0.5s;
      }
      a {
        position: relative;
        &:after {
          transition: all ease 0.5s;
          display: block;
          content: "";
          height: 1px;
          width: 0;
          background: $black;
          position: absolute;
          left: 0;
          bottom: -5px;
        }
      }
      a {
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }

    .swiper-pagination {
      color: $black;
      margin-left: 0;
      margin-right: 0;
      display: flex;
      gap: 70px;
      display: none;
      .swiper-pagination-bullet {
        width: auto !important;
        height: auto !important;
        border: none;
        background: none !important;
        margin: 0;
        opacity: 0.35;
        font-family: $font-t;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
        pointer-events: none;
      }
    }
  }


  #swiper-video {
    &.white {
      .content {
        color: $white;

        .h4, a {
          color: $white;
          &:after {
            background: $white;
          }
        }
      }

      .swiper-pagination {
        color: $white;
      }

      .arrow {
        filter: invert(100%);
      }

      .slide_progress-bar {
        background: rgba(255, 255, 255, 0.3);
        span {
          background: $white;
        }
      }
    }
  }


  .arrow {
    position: absolute;
    left: 50%;
    bottom: 50px;
    z-index: 30;
    animation-duration: 1s;
    opacity: 0;
    transition: all ease 0.5s;
    animation-iteration-count: infinite;
    animation-name: bounce-2;
    animation-timing-function: ease;
    cursor: pointer;
    margin-left: -10px;
    @media screen and (min-width: $width-xlarge) {
      bottom: 120px
    }

    &.active {
      opacity: 1;
    }
  }

  .count-1 {
    .content {
      display: none;
    }
  }

}

@keyframes bounce-2 {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.swiper-progress-bar {
  position: relative;
  width: 100%;
  display: block;
  z-index: 1;
  height: 2px;
  margin-top: 5px;
  margin-bottom: 15px;

  .slide_progress-bar {
    position: absolute;
    height: 2px;
    background: rgba(0, 0, 0, 0.3);
    width: auto;
    clear: both;
    opacity: 1;
    left: 0;
    right: 0;
    span {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      height: 100%;
      width: 0;
      content: "";
      transition: none;
    }
  }
  &.animate {
    .slide_progress-bar {
      span {
        transition: 0.1s width linear;
        transition-delay: unset;
        width: 100%;
      }
    }
  }

}
