.gutenberg-vin-parcelle {


  .onglets-desktop {
    @media screen and (max-width: $width-large) {
      display: none;
    }
    @media screen and (min-width: $width-large) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: solid 1px $beige_b;
      border-bottom: solid 1px $beige_b;
      border-right: solid 1px $beige_b;
    }


    li {
      display: block;
      width: 100%;
      font-size: 1rem;
      text-align: center;
      border-left: solid 1px $beige_b;
      position: relative;

      .first {
        width: 100%;
        height: 60px;
        background-color: $beige;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        div {
          position: relative;
        }

        &:before {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url(../img/volute-onglet-1.svg);
          background-size: 204px 60px;
          background-repeat: no-repeat;
          background-position: left bottom;
        }

      }

      .second {
        width: 100%;
        opacity: 0;
        position: absolute;
        bottom: -35px;
        height: 50px;
        pointer-events: none;
        .cta {
          width: 100%;
        }
      }

      span {
        font-family: $font-t;
        font-size: 1.5rem;
        display: block;
        margin-top: 5px;
        letter-spacing: 1px;
      }

      &:before {
        display: none !important;
      }

      &:nth-child(2), &:nth-child(6) {
        .first {
          &:before {
            background-position: right top;
            background-image: url(../img/volute-onglet-2.svg);
          }
        }
      }

      &:nth-child(3), &:nth-child(7) {
        .first {
          &:before {
            background-image: url(../img/volute-onglet-3.svg);
          }
        }
      }

      &:nth-child(4), &:nth-child(8) {
        .first {
          &:before {
            background-image: url(../img/volute-onglet-4.svg);
          }
        }
      }

      &:hover {
        .second {
          opacity: 1;
          pointer-events: all;
          .cta {
            background: $black !important;
            color: $white !important;
          }
        }
        .first {
          position: absolute;
          bottom: 15px;
          color: $white;
          &:before {
            filter: invert(100%);
            mix-blend-mode: soft-light;
            opacity: 0.6;
          }
        }
      }

      &:first-child {
        &:hover {
          .first {
            color: $black;
          }
        }
      }

    }

  }

  select {
    @media screen and (min-width: $width-large) {
      display: none;
    }
    @media screen and (max-width: $width-large) {
      display: block;
      font-family: $font-t;
      font-size: 1.8rem;
      width: calc(100% - 6rem);
      -webkit-appearance: none;
      border-radius: 50px;
      border: solid 1px $beige_b;
      padding: 8px 20px;
      background-color: transparent;
      position: relative;
      text-align: center;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63545 5.53553L1.6857 0.585786L0.271484 2L6.63545 8.36396L12.9994 2L11.5852 0.585786L6.63545 5.53553Z" fill="%23414141"/></svg>');
      background-repeat: no-repeat;
      background-position: calc(100% - 20px) center;
      margin: 2.4rem 3rem;
      margin-top: 0;
      color: $black;
    }
  }

  .content {
    overflow: hidden;
    @media screen and (max-width: $width-large) {
      padding-left: $padding;
      padding-right: $padding;
    }

    .item-neutre {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media screen and (min-width: $width-large) {
        height: 32vw;
      }
      &.hidden {
        display: none;
      }
      @media screen and (max-width: $width-large) {
        flex-direction: column;
        display: none;
        &.active {
          display: flex;
        }
      }
      img {
        height: auto;
        @media screen and (min-width: $width-large) {
          width: 50%;
          margin-top: 1.5vw;
        }
        @media screen and (max-width: $width-large) {
          width: auto;
          margin-left: -3rem;
          margin-right: -3rem;
          margin-bottom: 2rem;
        }
      }
      .intro {
        text-align: center;
        width: 350px;
        max-width: 100%;
       margin-left: 10rem;
        margin-right: 10rem;
        .title {
          font-family: $font-t;
          font-size: 2.2rem;
          margin-bottom: 2rem;
          display: block;
        }
      }
    }

    > .item:not(.item-neutre) {
      @media screen and (min-width: $width-large) {
        display: none;
        height: 32vw;
      }
      &.hover {
        display: grid;
      }

      @media screen and (max-width: $width-large) {
        display: none;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(4, auto);
        grid-column-gap: 16px;
        grid-row-gap: 24px;
        padding-bottom: 4rem;

        &.active {
          display: grid;
        }
      }

      @media screen and (min-width: $width-large) {
        grid-template-columns: 50% 200px calc(50% - 200px);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
      }

      @media screen and (min-width: $width-large) and (max-width: 1500px)  {
        grid-template-columns: 50% 150px calc(50% - 150px);
      }
    }

    .map {

      @media screen and (max-width: $width-large) {
        grid-area: 1 / 1 / 2 / 3;
        margin-left: -3rem;
        margin-right: -3rem;
        min-height: 64vw;
        width: auto;
        height: auto;
      }

      @media screen and (min-width: $width-large) {
        grid-area: 1 / 1 / 2 / 2;
        width: 100%;
        height: auto;
      }
    }
    .vin {
      img {
        height: auto;
      }
      @media screen and (max-width: $width-large) {
        grid-area: 2 / 1 / 3 / 2;
      }

      @media screen and (min-width: $width-large) {
        grid-area: 1 / 2 / 2 / 3;
        display: flex;
        align-items: flex-start;
        padding-top: 8rem;

        img {
          width: 110px;
          display: block;
        }

      }
      @media screen and (min-width: $width-large) and (max-width: 1500px)  {
        padding-top: 5rem;
        img {
          width: 90px;
        }
      }
    }

    h3 {
      @media screen and (max-width: $width-large) {
        display: none;
      }

      @media screen and (min-width: $width-large) {
        font-size: 2.2rem;
      }
    }



    .description {
      @media screen and (max-width: $width-large) {
        grid-area: 2 / 2 / 3 / 3;
      }

      @media screen and (min-width: $width-large) {
        grid-area: 1 / 3 / 2 / 4;

      }
      @media screen and (min-width: $width-large) and (max-width: 1500px)  {
        p {
          font-size: 12px;
        }
      }

    }

    .item {
      > .description {
        @media screen and (min-width: $width-large) {
          display: none;
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media screen and (max-width: $width-large) {
        grid-area: 3 / 1 / 4 / 3;
        .description {
          display: none;
        }
      }

      @media screen and (min-width: $width-large) {
        padding-top: 4rem;
        grid-area: 1 / 3 / 2 / 4;
        padding-right: 10rem;

      }

      @media screen and (min-width: $width-large) and (max-width: 1500px)  {
        padding-right: 2rem;
        padding-top: 2rem;
      }



      .item {
        display: grid;
        grid-template-columns: 80px auto;
        grid-template-rows: repeat(3, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        @media screen and (max-width: $width-large) {
          grid-template-columns: 50px auto;
        }

        img {
          grid-area: 1 / 1 / 3 / 2;
        }

        .title {
          grid-area: 1 / 2 / 2 / 3;
          text-transform: uppercase;
          font-family: $font-t;
          font-size: 1.5rem;
          letter-spacing: 1px;
        }

        .text {
          grid-area: 2 / 2 / 3 / 3;
          font-size: 1.3rem;

          > span {
            &:after {
              display: inline-block;
              content: "•";
            }
            &:last-child {
             &:after {
               display: none;
             }
            }
          }
        }
      }
    }

    .container-cta {
      @media screen and (min-width: $width-large) {
        display: none;
      }
      @media screen and (max-width: $width-large) {
        grid-area: 4 / 1 / 5 / 3;
        text-align: center;
      }
    }
  }

}