#age-gate-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $beige;
  color: white;
  text-align: center;
  padding: $padding;
  padding-top: 15%;
  font-size: 1.3rem;
  background-image: url(../img/volute-agegate.svg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left top;
  overflow: scroll;
  transition: opacity 1s;
  @media screen and (min-width: $width-small) {
    align-items: center;
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  @media screen and (max-height: 700px) {
    align-items: start;
  }

  .inner {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .content {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    margin-bottom: 3rem;
  }

  .legal {
    font-size: 1.3rem;
    margin-top: 4.5rem;
  }

  .logo {
    margin-bottom: 3rem;
    @media screen and (min-width: $width-small) {
      margin-bottom: 4.5rem;
    }
  }

  .fields {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
    @media screen and (min-width: $width-small) {
      flex-direction: row;
    }

    .item {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &:first-child {
        min-width: 210px;
      }

      &:nth-child(2) {
        max-width: 100%;
        select {
          width: 100%;
        }
        @media screen and (min-width: $width-small) {
          max-width: 300px;
        }
      }
    }
  }

  input[type=submit] {
    margin-top: 2rem;
  }

  .error {
    color: $rouge;
    @media screen and (min-width: $width-small) {
      max-width: 160px;
      text-align: center;
      &#error_age {
        max-width: 100%;
        margin-top: 2rem;
      }
    }
  }

  .danger {
    font-size: 1rem;
    opacity: 0.6;
    width: 100%;
    padding: 3rem;
    position: relative;
    @media screen and (min-width: $width-small) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    @media screen and (max-height: 700px) {
      position: relative;
    }

  }


  .symbol {
    width: 102px;
    height: 118px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    @media screen and (min-width: $width-medium) {
      width: 472px;
      height: 544px;
      display: block;
      position: absolute;
      top: 5rem;
      left: -170px;
      z-index: -1;
    }
  }

  p {
    a {
      color: $or;
    }
  }
}