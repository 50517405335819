.gutenberg-bg-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  color: $white;
  overflow: hidden;
  min-height: 149.4vw;

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
  }

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    z-index: 1;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: $width-small) {
      background-image: url("../img/cadre-m.svg");
      background-image: none;
    }
    @media screen and (min-width: $width-small) {
      background-image: url("../img/cadre.svg");
    }
  }

  @media screen and (max-width: $width-small) {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media screen and (min-width: $width-small) {
    min-height: 34.7vw;
  }
  .content {
    z-index: 10;
    text-align: center;
    max-width: 560px;
    @media screen and (max-width: $width-medium) {
      max-width: 460px;
    }
    @media screen and (max-width: $width-small) {
      position: relative;
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: -20rem;
        right: -20rem;
        top: -2rem;
        bottom: -2rem;
        border: solid 1px $white;
        opacity: 0.2;
        border-left: none;
        border-right: none;
      }
      &:after {
        display: block;
        content: "";
        position: absolute;
        left: -2rem;
        right: -2rem;
        top: -40rem;
        bottom: -40rem;
        border: solid 1px $white;
        opacity: 0.2;
        border-top: none;
        border-bottom: none;
      }
    }
    p {
      font-family: $font-t;
      color: $white;
      &.small {
        font-size: 1.5rem;
        @media screen and (max-width: $width-medium) {
          font-size: 1.2rem;
        }
        @media screen and (max-width: $width-small) {
          font-size: 1.5rem;
        }
      }

    }
    .title {
      margin-bottom: 1rem;
      color: $white;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo {
    position: absolute;
    z-index: 10;
    right: 17vw;
    width: 8vw;
    height: auto;
    top: calc(50% - 9vw);

    @media screen and (min-width: $width-small) {
      transform: translateY(-50%);
    }

    @media screen and (max-width: $width-medium) {
      width: 6vw;
      height: auto;
      right: 18vw;
    }
    @media screen and (max-width: $width-small) {
      width: 90px;
      display: block;
      position: relative;
      left: inherit;
      right: inherit;
      top: inherit;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4rem;
    }
  }
}

