// Flèche améliorée

@mixin indian-arrow($color: black, $direction: 0deg) {
  &:before {
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 11" style="enable-background:new 0 0 18 11;" xml:space="preserve"><g id="SCROLL_Arrow"><path id="Vector" class="st0" d="M11.4,0.6c-0.2,0.3-0.2,0.6,0.1,0.8l1.9,1.4l2.4,2.1H0.6C0.3,4.9,0,5.2,0,5.5c0,0.3,0.3,0.6,0.6,0.6h15l-4.2,3.5c-0.3,0.2-0.3,0.6-0.1,0.8c0.2,0.2,0.6,0.3,0.9,0.1l5.5-4.6C17.9,5.8,18,5.6,18,5.5v0c0-0.2-0.1-0.3-0.2-0.4l-3.6-3.1l-1.9-1.4C12,0.3,11.7,0.4,11.4,0.6L11.4,0.6z"/></g></svg>');
    background-size: 100% auto;
    width: 20px;
    height: 12px;
    content: "";
    display: inline-block;
    transform: rotate($direction);

    @if $color == white {
      filter: invert(98%) sepia(98%) saturate(21%) hue-rotate(25deg) brightness(106%) contrast(96%);
    }
  }
}

@mixin arrow-after($color: black, $direction: 0deg) {
  &:after {
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 11" style="enable-background:new 0 0 18 11;" xml:space="preserve"><g id="SCROLL_Arrow"><path id="Vector" class="st0" d="M11.4,0.6c-0.2,0.3-0.2,0.6,0.1,0.8l1.9,1.4l2.4,2.1H0.6C0.3,4.9,0,5.2,0,5.5c0,0.3,0.3,0.6,0.6,0.6h15l-4.2,3.5c-0.3,0.2-0.3,0.6-0.1,0.8c0.2,0.2,0.6,0.3,0.9,0.1l5.5-4.6C17.9,5.8,18,5.6,18,5.5v0c0-0.2-0.1-0.3-0.2-0.4l-3.6-3.1l-1.9-1.4C12,0.3,11.7,0.4,11.4,0.6L11.4,0.6z"/></g></svg>');
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 20px;
    height: 12px;
    content: "";
    display: inline-block;
    transform: rotate($direction);

    @if $color == white {
      filter: invert(98%) sepia(98%) saturate(21%) hue-rotate(25deg) brightness(106%) contrast(96%);
    }
  }
}

.only-mobile {
  display: none;
  @media screen and (max-width: $width-medium) {
    display: block;
  }
}

.only-desktop {
  @media screen and (max-width: $width-medium) {
    display: none;
  }
}

.only-medium {
  display: none;
  @media screen and (max-width: 1240px) {
    display: block;
  }
}