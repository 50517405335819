.gutenberg-text-3-img {
  overflow: hidden;
  position: relative;
  border-top: solid 1px $beige_b;


  .content {
    background: $white_t;
    text-align: center;
    padding: 4.5rem $padding;
    @media screen and (min-width: $width-medium) {
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 6rem;
      padding-right: 6rem;


      * {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .sous-titre {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-family: $font-t;
    margin-top: -3rem;
    display: block;
    margin-bottom: 4rem;
  }

  .citation {
    font-family: $font-t;
    font-size: 1.8rem;
    font-style: italic;
    margin-bottom: 4rem;
    display: block;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $width-medium) {
      font-size: 2.2rem;
    }
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
  }

  .cta {
    margin-top: 4rem;
  }

  .photo-1,
  .photo-2,
  .photo-3 {
    aspect-ratio: 1;
    overflow: hidden;
    position: relative;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .photo-2 {
    img {
      @media screen and (max-width: $width-medium) {
        width: 85%;
      }
    }
    @media screen and (max-width: $width-medium) {
      &:before {
        display: block;
        content: "";
        width: 100%;
        height: 50%;
        position: absolute;
        background: $white_t;
        z-index: -1;
        top: 0;
        right: 0;
      }
    }
    @media screen and (min-width: $width-medium) {
      position: absolute;
      width: 16.4vw;
      right: 18vw;
      top: 5vw;
    }
  }

  .photo-3 {
    width: 60%;
    float: right;
    margin-top: -10%;
    margin-bottom: 1.5rem;
    @media screen and (min-width: $width-medium) {
      position: absolute;
      width: 11.7vw;
      right: 12vw;
      top: 34vw;
    }
  }

  .photo-1 {
    @media screen and (min-width: $width-medium) {
      position: absolute;
      width: 19.5vw;
      left: 14vw;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
}
