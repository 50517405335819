.single-vins {

  &.fixed-menu-vin {
    padding-top: 5rem;
  }

  .gutenberg-hero-title.vin {
    padding-top: 2rem;
    padding-bottom: 5rem;
    height: auto;
    @media screen and (max-width: $width-medium) {
      background-image: none !important;
      margin-top: 0;

    }

    @media screen and (min-width: $width-medium) {
      border-top: solid 1px $beige_b;
      margin-top: 10rem;
      background: none;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 70%;
    }



    &:before {
      display: none;
    }

    &:after {
      background-image: url(../img/volute-header-vin.svg);
      height: 100%;
      background-size: auto 100%;
      @media screen and (max-width: $width-medium) {
        display: none;
      }
    }

    h1 {
      @extend .h3;
      text-align: left;
      margin-bottom: 0.5rem;
    }

    .content-hero {
      display: flex;

      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;

      @media screen and (max-width: $width-medium) {
        flex-direction: column;
      }

      @media screen and (min-width: $width-medium) {
        flex-direction: row;
      }

    }

    .vide {
      @media screen and (max-width: $width-medium) {
        display: none;
      }
      @media screen and (min-width: $width-medium) {
        flex-basis: calc(390px - 5.5rem);
        width: calc(390px - 5.5rem);
        height: 300px;
      }
    }

    .vin {
      display: block;
      margin-left: auto;
      margin-right: auto;
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $width-medium) {
        width: 100%;
      }
      @media screen and (min-width: $width-medium) {
        flex-basis: 600px;
        width: 600px;
        height: 600px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: $width-medium) {
          width: 107px;
          height: auto;
        }
      }
    }

    .content {
      @media screen and (max-width: $width-medium) {
        margin-top: 5rem;
      }
      @media screen and (min-width: $width-medium) {
        flex-basis: 390px;
        width: 390px;
        padding-right: $padding;
        /*margin-left: -5.5rem;*/
      }

      .millesime {
        @extend .sous-titre;
      }

      .logos, .medailles {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        position: relative;
        z-index: 30;

        .caracteristiques {
          .item {
            position: relative;
            display: inline-block;
          }
          .info {
            position: absolute;
            left: 0;
            font-size: 12px;
            text-align: left;
            min-width: 80px;
            top: 72px;
            background: $white;
            padding: 5px;
            color: $black;
            opacity: 0;
            transition: opacity 0.2s;
            border-radius: 4px;
            transform: translateX(-50%);
            left: 50%;
            &:after {
              display: inline-block;
              content: "";
              position: absolute;
              top: -5px;
              left: 50%;
              margin-left: -5px;
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 0 5px 5px 5px;
              border-color: transparent transparent $white transparent;
              transform: rotate(0deg);
            }
          }
          .item {
            &:hover {
              .info {
                opacity: 1;
              }
            }
          }
        }


      }

      .description {
        max-width: inherit;
        margin-top: 2rem;
        p {
          @extend .p;
          text-align: left;
          a {
            color: $black_p;
            text-decoration: underline;
            &:hover {
             font-weight: bold;
            }
          }
        }
      }

      .cta {
        margin-top: 2rem;
      }

    }
  }

  .gutenberg-text-img {
    .images {
      .content {
        &:after {
          display: none !important;
        }
      }
    }
    .texts {
      .signature {
        width: 208px;
        height: auto;
        margin-left: 5rem;
        margin-top: -4rem;
        @media screen and (max-width: $width-small) {
          width: 160px;
          margin-left: 3rem;
        }
      }
      &:after {
        display: none !important;
      }
    }
  }

  .vin-collection-mobile {
    display: none;
    @media screen and (max-width: $width-medium) {
      display: block;
     padding-top: 10rem;
      padding-left: 2rem;
      padding-right: 2rem;

      .select {
        font-size: 1.5rem;
        display: flex;
        border: solid 1px $beige_b;
        border-radius: 400px;
        padding: 8px 20px;
        max-width: 100%;
        width: 345px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        gap: 5px;
        flex-wrap: wrap;
        .item {
          position: relative;
          select {
            -webkit-appearance: none;
            font-weight: bold;
            border: none;
            background: transparent;
            padding-right: 15px;
            position: relative;
            z-index: 10;
            outline: none;
            max-width:175px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
            color: $black;
          }
          &:last-child {
            &:after {
              display: inline-block;
              position: absolute;
              content: "";
              width: 13px;
              height: 9px;
              right: 0;
              top: 6px;
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63545 5.53553L1.6857 0.585786L0.271484 2L6.63545 8.36396L12.9994 2L11.5852 0.585786L6.63545 5.53553Z" fill="%23414141"/></svg>');
            }
          }
        }
      }

    }
  }

  .vin-collection {
    position: absolute;
    left: 6rem;
    bottom: 5rem;
    @media screen and (max-width: $width-medium) {
      display: none;
    }

    .sous-titre {
      display: block;
    }

    .logo {
      margin-bottom: 2rem;
    }
    ul {
      border-left: solid 1px $beige_b;
      padding-left: 2rem;
      li {
        &:before {
          display: none !important;
        }
        &.inactive {
          opacity: 0.3;
        }

        a {
          transition: none;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  .vin-menu {
    height: 5rem;
    background: $blue;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-out;
    @media screen and (max-width: $width-medium) {
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      padding-left: 3rem;
      padding-right: 3rem;
      justify-content: flex-start;
      width: 100%;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $width-medium) {
        gap: 3rem;
      }
      @media screen and (min-width: $width-medium) {
        gap: 10rem;
      }
      li {
        &:before {
          display: none !important;
        }
        a {
          color: white;
          text-transform: uppercase;
          font-size: 1.3rem;
          position: relative;
          &:after {
            display: block;
            content: "";
            height: 1px;
            width: 0;
            background: $white;
            margin-top: -2px;
            transition: width 0.5s;
          }
          &.active, &:hover {
             &:after {
               width: 100%;
             }
          }
        }

      }
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    }
  }

  &.fixed-menu {
    .vin-menu {
      &.fixed {
        top: 7.5rem;
        @media screen and (max-width: $width-medium) {
          top: 4.5rem;
        }
      }
    }
  }


  .collection-produits {
    .h3 {
      border-bottom: solid 1px $beige_b;
    }
    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3rem;
    }

    .items {
      &.count-1 {
        justify-content: center;
        .miniature-vin {
          border-left: solid 0.5px #ddd6cc;
          border-right: solid 0.5px #ddd6cc;
        }
      }
    }


  }


  .cta-wine {
    &.hidden {
      display: none;
    }
  }


}



.vin-recettes {
  position: relative;
  padding: 4.5rem $padding;
  border-top: solid 1px $beige_b;
  border-bottom: solid 1px $beige_b;
  @media screen and (max-width: $width-medium) {
    padding-bottom: 0;
  }

  > .title {
    @extend .h3;
    text-align: center;
    margin-bottom: 1rem;
    @media screen and (max-width: $width-small) {
      font-size: 3rem;
    }
  }

  .sous-titre {
    text-align: center;
    margin-bottom: 3.5rem;
    display: block;
    @media screen and (min-width: $width-medium) {
      margin-bottom: 6rem;
    }
  }

  .swiper-pagination {
    @media screen and (min-width: $width-small) {
      margin-top: 4rem;
    }
    @media screen and (max-width: $width-small) {
      position: absolute;
      top: 85vw;
      width: 100%;
      height: 30px;
    }
  }

  .swiper-recettes {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .swiper-slide {
    @media screen and (max-width: $width-small) {
      height: auto;
    }
    @media screen and (min-width: $width-small) {
      display: flex;
      align-items: center;
      max-width: 840px;
      justify-content: space-between;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      @media screen and (max-width: $width-small) {
        margin-bottom: 4rem;
      }
      @media screen and (min-width: $width-small) {
        width: 65%;
      }
    }

    .content {
      color: $white;
      height: 100%;
      position: relative;
      background: #c19f5f;
      @media screen and (max-width: $width-medium) {
        padding: 6rem 2rem;
      }
      @media screen and (min-width: $width-medium) {
        overflow: hidden;
        background: $or_t;
        padding: 8.5rem 5rem;
      }

      &:after {
        width: 150px;
        height: 330px;
        display: block;
        content: "";
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        background-image: url(../img/barbaccus-recette.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: 0;
        left: 0;
        mix-blend-mode: multiply;
        @media screen and (max-width: $width-small) {
          left: -3rem;
          opacity: 0;
        }
      }

      @media screen and (min-width: $width-small) {
        margin-left: -120px;
        width: 420px;
      }

      @media screen and (max-width: $width-small) {
        .text {
          display: none;
        }
      }

      .infos {
        text-transform: uppercase;
        display: flex;
        gap: 4px;
        margin-top: 0.5rem;

      }

      .introduction {
        margin-top: 2.5rem;
        font-style: italic;
        font-size: 1.3rem;
      }


      .cta {
        margin-top: 2.5rem;
        position: relative;
        z-index: 10;
      }

      .text {
        position: relative;
      }

      p {
        color: $white;
        font-style: italic;
        font-size: 1.3rem;

      }

      .title {
        font-style: italic;
        color: $white;
      }

      .vin {
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-top: .5rem;
        margin-bottom: 2rem;
        display: block;
        font-weight: 400;
      }
    }
    @media screen and (max-width: $width-small) {
      &.swiper-slide-active {
        .content {

          .text {
            display: block;
            z-index: 10;
          }
          &:after {
            opacity: 1;
          }
          &:before {
            display: block;
            content: "";
            background: #c19f5f;
            position: absolute;
            left: -5rem;
            right: -3rem;
            top: 0;
            bottom: 0;
            z-index: -1;
          }
        }
      }
    }
  }

  .swiper-navigation {
    display: none;
    @media screen and (min-width: $width-small) {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      width: 100%;
      display: block;
      .swiper-button-next,
      .swiper-button-prev {
        position: absolute;
      }
      .swiper-button-next {
        right: calc(40px + 1vw);
      }
      .swiper-button-prev {
        left:  calc(40px + 1vw);
      }
    }
    @media screen and (min-width: $width-small) {
      .swiper-button-next {
        right: calc(40px + 1vw);
      }
      .swiper-button-prev {
        left:  calc(40px + 1vw);
      }
    }
    @media screen and (min-width: $width-medium) {
      width: 960px;
      left: 50%;
      transform: translateX(-50%);
      height: 20px;
      top: 40%;
      z-index: 10;

      .swiper-button-next {
        right: 0;
        z-index: 1;
      }
      .swiper-button-prev {
        left:  0;
        z-index: 1;
      }
    }
    @media screen and (min-width: $width-large) {
      width: 950px;
    }
    @media screen and (min-width: $width-xlarge) {
      width: 1200px;
    }
  }
}