.gutenberg-mots-vignerons {
  position: relative;
  padding: 4.5rem $padding;
  border-bottom: solid 1px $beige_b;

  background-image: url(../img/volute-complete.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;


  @media screen and (min-width: $width-small) {
    background-position: center center;
  }

  @media screen and (max-width: $width-small) {
    background-position: -50px 20px;
    padding-bottom: 0;
  }

  > .title {
    text-align: center;
    @extend .h4;
    margin-bottom: 3rem;
    @media screen and (min-width: $width-medium) {
      margin-bottom: 3.5rem;

    }
    @media screen and (max-width: $width-small) {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .swiper-pagination {
    @media screen and (min-width: $width-small) {
      margin-top: 4rem;
    }
    @media screen and (max-width: $width-small) {
      position: absolute;
      top: 320px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .swiper-mots-vignerons {
      margin-left: -3rem;
      margin-right: -3rem;
  }

  .swiper-slide {
    @media screen and (max-width: $width-small) {
      height: auto;
      max-width: 310px;
    }

    @media screen and (min-width: $width-small) {
      display: flex;
      align-items: center;
      max-width: 840px;
      justify-content: space-between;
    }


    img {
      width: 100%;
      height: auto;
      display: block;
      @media screen and (max-width: $width-small) {
        margin-bottom: 4rem;
      }
      @media screen and (min-width: $width-small) {
        width: 65%;
      }
    }

    .content {
      background: $rouge;
      color: $white;
      padding: 5rem 2rem;
      position: relative;
      height: 100%;
      @media screen and (min-width: $width-small) {
        margin-left: -120px;
        padding: 5rem;
        width: 420px;
      }

      @media screen and (max-width: $width-small) {
        .text {
          display: none;
        }
      }

      p {
        color: $white;
        font-style: italic;
        font-size: 1.3rem;
      }

      .title {
        font-style: italic;
        color: $white;
      }

      .vin {
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-top: .5rem;
        margin-bottom: 2rem;
        display: block;
        font-weight: 400;
      }
    }


    @media screen and (max-width: $width-small) {
      &.swiper-slide-active {
        .content {
          .text {
            display: block;
          }
          &:before {
            display: block;
            content: "";
            background: $rouge;
            position: absolute;
            left: -5rem;
            right: -3rem;
            top: 0;
            bottom: 0;
            z-index: -1;
          }
        }
      }
    }
  }

  .swiper-navigation {
    display: none;
    @media screen and (min-width: $width-small) {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      width: 100%;
      display: block;
      .swiper-button-next,
      .swiper-button-prev {
        position: absolute;
      }
      .swiper-button-next {
        right: calc(40px + 1vw);
      }
      .swiper-button-prev {
        left:  calc(40px + 1vw);
      }
    }
    @media screen and (min-width: $width-small) {
      width: calc(100% - 80px);
      left: 50%;
      transform: translateX(-50%);
      height: 20px;
      top: 40%;
      z-index: 10;

      .swiper-button-next {
        right: 0;
        z-index: 1;
      }
      .swiper-button-prev {
        left:  0;
        z-index: 1;
      }
    }
    @media screen and (min-width: $width-medium) {
      width: 960px;
    }
    @media screen and (min-width: $width-large) {
      width: 950px;
    }
    @media screen and (min-width: $width-xlarge) {
      width: 1200px;
    }
  }

}