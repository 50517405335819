@import "init/_fonts";

.gutenberg-text-2-img {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px $beige_b;
  @media screen and (min-width: $width-medium) {
    flex-direction: row;
    align-items: start;
  }

  .img {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: $width-medium) {
      width: 30%;
      flex-basis: 30%;
    }
  }

  .content {
    padding: 5rem $padding;
    text-align: center;
    @media screen and (min-width: $width-medium) {
      width: 40%;
      flex-basis: 40%;
      padding-left: 6rem;
      padding-right: 6rem;
      align-self: center;
    }
  }

  h2 {
    @extend .h4;
  }
  h2 {
    margin-bottom: 2rem;
    @media screen and (min-width: $width-medium) {
      margin-bottom: 2.5rem;
    }
  }

}
