.gutenberg-vins-filtres {
position: relative;
  background-image: url(../img/gravure-botanic.png);
  background-repeat: no-repeat;
  background-position: left top;
  padding: 4.5rem 0;
  border-top: solid 1px $beige_b;
  margin-top: -4px;
  @media screen and (min-width: $width-small) {
    background-size: 214px auto;
  }
  @media screen and (max-width: $width-small) {
    background-size: 120px auto;
    padding-left: $padding;
    padding-right: $padding;
  }

  @media screen and (min-width: $width-small) {
    &:after {
      display: block;
      content: "";
      position: absolute;
      background-image: url(../img/volute-vin.svg);
      background-repeat: no-repeat;
      background-size: auto 585px;
      background-position: right calc(100% - 40px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }


  .container-slider {
    position: relative;
    @media screen and (max-width: $width-small) {
      margin-left: -3rem;
      margin-right: -3rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @media screen and (min-width: $width-medium) {
      &:after {
        display: block;
        content: "";
        height: 100%;
        width: 200px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        background: rgb(239, 235, 230);
        background: linear-gradient(90deg, rgba(239, 235, 230, 1) 26%, rgba(255, 255, 255, 0) 100%);
      }
    }
    &:before {
      background-image: url(../img/logo-v.svg);
      background-repeat: no-repeat;
      background-position: left top;
      display: block;
      content: "";
      height: 100%;
      width: 170px;
      position: absolute;
      left: 0;
      z-index: 20;
      @media screen and (max-width: $width-medium) {
        z-index: -1;
      }
      @media screen and (min-width: $width-small) {
        background-size: 80px auto;
      }
      @media screen and (max-width: $width-small) {
        background-size: 65px auto;
        left: 0;
        width: 70px;
        z-index: 10;
        pointer-events: none;
      }
    }
  }

  h2 {
    font-size: 3.5rem;
  }
  h2, .sous-titre {
    text-align: center;
  }
  .sous-titre {
    margin-top: -2rem;
  }
  .section-filters {
    display: flex;
    border-top: solid 1px $beige_b;
    border-bottom: solid 1px $beige_b;
    justify-content: center;
    margin-top: 3.5rem;
    @media screen and (max-width: $width-small) {
      flex-direction: column;
      margin-left: -3rem;
      margin-right: -3rem;
    }
    > div {
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      font-family: $font-t;
      @media screen and (min-width: $width-small) {
        padding-right: 4rem;
        border-right: solid 1px $beige_b;
        font-size: 2.2rem;
        padding-left: 1rem;
      }
      @media screen and (max-width: $width-small) {
        justify-content: center;
        padding-top: 1.5rem;
        font-size: 1.8rem;
      }
    }
  }
  .collection-filter {
    display: flex;
    padding-left: 4rem;
    list-style: none;

    @media screen and (min-width: $width-small) {
      padding-right: 4rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      gap: 4rem;
    }
    @media screen and (max-width: $width-medium) {
      gap: 1rem;
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      padding-left: 20px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    li {
      cursor: pointer;
      display: flex;
      padding: 7px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 400px;
      border: solid 1px $beige_b;
      font-family: $font-t;
      font-size: 2.2rem;
      font-style: italic;
      text-align: center;
      transition: all .2s ease-out;
      &.active {
        background: $black;
        color: $white;
        border-color: $black;
      }
      &:hover {
        background: $beige-b;
        color: $black;
        border-color: $beige_b;
      }
      &:before {
        display: none !important;
      }
      span {
        line-height: 1.2;
        span {
          font-style: normal;
          text-transform: uppercase;
          font-family: $font-p;
          font-size: 1rem;
          display: block;
        }
      }

    }
  }
  .swiper-navigation {

    @media screen and (min-width: $width-small) {
      position: absolute;
      top: 330px;
      left: 50%;
      width: 30vw;
      min-width: 300px;
      margin-left: -15vw;
      justify-content: space-between;
      .swiper-pagination {
        display: none;
      }
    }
    @media screen and (max-width: $width-small) {
      margin-top: 0;
      margin-bottom: 4rem;
      .swiper-button-prev, .swiper-button-next {
        display: none;
      }
    }
  }

  .swiper-vins {
    display:none;
    &.active {
      display: block;
    }

    .swiper-button-disabled {
      opacity: 0;
    }

  }

  .bg {
    display: block;
    position: absolute;
    width: 360px;
    height: auto;
    left: 50%;
    margin-left: -180px;
    z-index: -1;
    opacity: 0;
  }



  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      display: block;
      content: "";
      width: 95px;
      height: 8px;
      position: absolute;
      bottom: 7.5rem;
      left: 50%;
      filter: blur(2px);
      margin-left: -30px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="103" height="16" viewBox="0 0 103 16" fill="none"><g opacity="0.2" filter="url(%23filter0_f_2757_4401)"><ellipse cx="51.5" cy="8" rx="47.5" ry="4" fill="%232B2829"/></g><defs><filter id="filter0_f_2757_4401" x="0" y="0" width="103" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2757_4401"/></filter></defs></svg>');
      opacity: 0;
      transition: opacity 1s;
    }

    a {
      pointer-events: none;
      img {
        width: auto;
        height: auto;
      }
    }

    @media screen and (min-width: $width-small) {
      padding-top: 7rem;
      padding-bottom: 7rem;
    }
    @media screen and (max-width: $width-small) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .swiper-wrapper {
margin-left: -10vw;
  }




  .swiper-slide-active {
   margin-right: 10vw;
    margin-left: 10vw;
    .bg, .content:after {
      opacity: 1;
    }
    .content {
      a {
        pointer-events: all;
      }
    }

  }


  .vin-description {
    text-align: center;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4.5rem;
    min-height: 55px;
  }

  .vin-link {
    position: absolute;
    left: calc(50% + 5vw);
    z-index: 100;
    top: 275px;
    @media screen and (min-width: $width-small) {
      left: calc(50% + 6vw);
    }
    @media screen and (max-width: $width-small) {
      left: calc(50% + 15vw);
    }
  }

}
