.gutenberg-vin-degustation {

  text-align: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 5rem $padding;

  display: flex;
  justify-content: center;
  flex-direction: column;


  @media screen and (max-width: $width-small) {
    background-position: center top;
  }

  @media screen and (min-width: $width-small) {
    height: 55.55vw;
    background-position: center bottom;
    padding: 5rem 2rem;
  }


  @media screen and (min-width: $width-large) {
    padding: 200px $padding;
  }



  h2 {
    @extend .h3;
    position: relative;
    z-index: 10;
    @media screen and (max-width: $width-small) {
      margin-bottom: 2.5rem;
      max-width: 195px;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: $width-small) {
      margin-bottom: 5rem;
    }
  }

  div {
    color: $black;
    font-family: $font-t;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $width-medium) {
    }
    @media screen and (min-width: $width-medium) {
      width: 930px;
    }
    @media screen and (min-width: $width-small)  and (max-width: $width-large) {
      width: 610px;
    }

    p {
      color: $black;
      font-family: $font-t;
      font-style: normal;
      font-weight: 400;
      line-height: 216%;
      letter-spacing: 1.1px;
      display: inline;
      @media screen and (max-width: $width-medium) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: $width-medium) {
        font-size: 2.2rem;
      }
      @media screen and (min-width: $width-small)  and (max-width: $width-large) {
        font-size: 1.6rem;
      }
    }

    a {
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
  }

  .item {


    .mot {
      border-radius: 400px;
      background: #00BDAC;
      display: inline;
      color: $white;
      font-style: italic;
      padding: 4px 22px;
      line-height: 125%;
      letter-spacing: 0;
      margin-left: 10px;
      margin-right: 10px;
      position: relative;
      z-index: 2;
      cursor: pointer;
      transition: background .5s;
      font-size: 1.8rem;
      word-break: keep-all;
      @media screen and (min-width: $width-medium) {
        font-size: 3.5rem;
        &:hover {
          background: $white;
          color: #00BDAC;;
          & + .hover {
            opacity: 1;
          }
        }
      }



    }

    .phrase {
      position: relative;
      z-index: 2;
    }


    .hover {
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
      transition: opacity 1s;
      height: auto;
      display: none;
      @media screen and (min-width: $width-medium) {
        display: block;
      }
    }

    &:first-child {
      .hover {
        width: 420px;
        left: 0;
        top: 12vw;
      }
    }

    &:nth-child(2) {
      .hover {
        width: 300px;
        right: 29vw;
        top: 0;
      }
    }

    &:last-child {
      .hover {
        width: 360px;
        bottom: 3vw;
        right: 12vw;
      }
    }
  }
}