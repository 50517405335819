.gutenberg-text-full {
  .content {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: $black;

    p, ul {
      margin-bottom: 2rem;
      a {
        color: $black_p;
        text-decoration: underline;
        &:hover {
          font-weight: bold;
        }
      }
    }

    ul {
      color: $black;
      padding-left: 3rem;
    }

    h2 {
      @extend .h3;
      margin-top: 4rem;
    }

    h3 {
      @extend .h4;
    }
  }
}