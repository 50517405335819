.wpcf7 {
  form {
    &.invalid .wpcf7-response-output, &.unaccepted .wpcf7-response-output, &.payment-required .wpcf7-response-output {
      background: $rouge;
      color: $white;
      padding: 1rem 2rem;
      border: none;
      font-size: 1.3rem;
    }
    width: 100%;
    .group-last {
      width: 100%;
      display: block !important;
    }

    .label {
      margin-bottom: 0.8rem;
      display: block;
      margin-top: 2rem;
      font-size: 1.3rem;
    }
    select, input[type=text], input[type=number], input[type=email], textarea {
      width: 100%;
    }

    textarea {
      width: 100%;
    }

    input[type=submit] {
      margin-top: 4.5rem;
      display: block;
      width: 145px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }

    .group {
      @media screen and (min-width: $width-medium) {
        display: flex;
        gap: 1.5rem;

        .item-20 {
          width: 20%;
          flex-basis: 20%;
        }

        .item-40 {
          width: 40%;
          flex-basis: 40%;
        }

        .item-60 {
          width: 60%;
          flex-basis: 60%;
        }
      }
    }

  }
  .wpcf7-list-item {
    margin: 0;
    margin-right: 1rem;
  }

  .wpcf7-radio {
    display: block;
    margin-top: 2rem;
  }

  .wpcf7-not-valid-tip {
    color: $rouge;
    margin-top: 1rem;
    font-size: 1.3rem;
  }



  select {
    width: 100%;
  }
}