body.page-id-167, body.page-id-1279, body.page-id-1280 {
  .gutenberg-hero-title {
    overflow: visible;

    @media screen and (min-width: $width-medium) {
      height: auto;
      padding-top: 210px;
      padding-bottom: 90px;
    }

  }
}

.gutenberg-faq {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $padding;
  .item {
    border-bottom: solid 1px $beige_b;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  .question {
    font-weight: bold;
    overflow: auto;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
    position: relative;
    padding-right: 5rem;
    min-height: 40px;
  }
  .reponse {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    &.active {
      opacity: 1;
      padding-top: 2rem;
      max-height: 200px;
    }
    p {
      a {
        color: $black_p;
        text-decoration: underline;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
  .arrow-round {
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease;
  }
  .question {
    &.active {
      .arrow-round {
        transform: rotate(0deg);
      }
    }
  }
}
