@import "init/_mixins";

.gutenberg-anchor {
  display: flex;
  .swiper {
    height: calc(79vw + 5rem);
    @media screen and (max-width: $width-medium) {
      padding-right: 29vw;
      padding-bottom: 5rem;
    }
    @media screen and (min-width: $width-medium) {
      height: 36vw;
    }
    &.multiple {
      @media screen and (min-width: $width-medium) {
        padding-right: 16vw;
        padding-bottom: 5rem;
      }
    }
  }
  .swiper-navigation {
    position: absolute;
    width: 100%;
  }
  .title {
    font-style: italic;
    position: relative;
    font-size: 3rem;
    line-height: 125%;
    @media screen and (min-width: $width-large) {
      font-size: 3.5rem;
    }
  }
  .discover {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-size: 1.4rem;
    position: absolute;
    bottom: $padding;
    left: $padding;
    @include indian-arrow(white, 90deg);
    &:before {
      width: 20px;
      height: 13px;
      margin-right: 10px;
    }

  }
  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .title, .discover {
    color: $white;
    z-index: 10;
  }
  .swiper-slide {
    padding: $padding;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transition: background 0.3s ease-out;
    overflow: hidden;
    @media screen and (max-width: $width-medium) {
      background-size: cover;
    }

    &:after {
      display: block;
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 45.5%, rgba(0, 0, 0, 0.00) 79.86%, rgba(0, 0, 0, 0.30) 100%);
      background-blend-mode: multiply, normal;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:before {
      display: block;
      content: "";
      background-image: url("../img/volute-ancre-2.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      background-size: 100% auto;
      background-position: center bottom;
      position: absolute;
      top: 0;
      opacity: 0;
      right: 0;
      transition: all 0.3s ease-out;
    }

    &:hover {
      background-size: 110% auto;
      &:before {
        opacity: 1;
      }
    }

  }
}