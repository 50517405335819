$width-xsmall: 500px;
$width-small: 768px;
$width-medium: 1025px;
$width-large: 1200px;
$width-xlarge: 1400px;

$padding: 3rem;
$padding-y: 3.5rem;
$padding-d: 7rem 12rem;

$beige: #EFEBE6;
$beige_b: #DDD6CC;
$black: #2B2829;
$black_p: #414141;
$white: #FCFAFA;
$white_t: rgba(252, 250, 250, 0.95);
$or: #BE9A56;
$or_t: rgba(190, 154, 86, 0.95);
$rouge: #A02323;
$rose: #F680A3;
$aurore: #F5DC9B;
$yellow_t: rgba(245, 220, 155, 0.95);
$blue: #00BDAC;

@import "init/_mixins";

*:focus {
  outline: none;
}

.arrow {
  @include indian-arrow(black, 90deg);
  &.arrow-white {
    background-image: url('data:image/svg+xml,<svg width="12" height="30" viewBox="0 0 12 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.79243 19.7844H6.56101V9.37747L8.74194 7.32512V0L6 2.58029L3.25806 0V7.32512L5.43899 9.37747V19.7844H5.20757L1 18.0026L6 30L11 18.0026L6.79243 19.7844Z" fill="%23FCFAFA"/></path></svg>');
  }
}

.cta {
  background: $black;
  border-radius: 2px;
  padding: 14px 35px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  color: $white;
  text-decoration: none;
  display: inline-block;
  font-size: 1.4rem;
  @media screen and (max-width: $width-small) {
    padding: 14px 25px;
  }
  &:hover {
    background: $beige_b;
    color: $black;
  }
}

.cta-second {
  background: $or;
  border-radius: 2px;
  padding: 14px 35px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  color: $white;
  text-decoration: none;
  display: inline-block;
  font-size: 1.4rem;
  @media screen and (max-width: $width-small) {
    padding: 14px 25px;
  }
  &:hover {
    background: rgba(163, 132, 75, 1);
  }
}

.text-link {
  color: $black_p;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 1.4px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

form {
  label {
    color: $black_p;
  }

  select, input[type=text], input[type=number], input[type=email], textarea {
    border-radius: 2px;
    background: #fff;
    border: solid 1px $beige_b;
    height: 45px;
    padding: 5px 15px;
    color: $black_p;
  }

  textarea {
    height: auto;
  }

  select {
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }

  input[type=submit] {
    @extend .cta;
    border: none;
  }
}

.text-center {
  text-align: center;
}

.sous-titre {
  display: block;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: ivymode,sans-serif;
  line-height: 170%;
  font-size: 1.5rem;
}

// Flèche ronde

.arrow-round {
  display: block;
  width: 39px;
  height:39px;
  background: $black;
  border: solid 1px $black;
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 9" style="enable-background:new 0 0 16 9;" xml:space="preserve"><path id="Vector" class="st0" d="M8.1,9c0.2,0,0.3-0.1,0.4-0.2l7.2-7.2C15.9,1.4,16,1.2,16,1c0-0.2,0-0.5-0.2-0.7C15.7,0.1,15.5,0,15.3,0c-0.2,0-0.4,0.1-0.6,0.2L8,6.9L1.3,0.2C1.2,0.1,0.9,0,0.7,0C0.5,0,0.3,0.1,0.2,0.3C0.1,0.5,0,0.7,0,1c0,0.2,0.1,0.5,0.3,0.6l7.2,7.2C7.6,8.9,7.9,9,8.1,9L8.1,9z"/></svg>');
  background-repeat: no-repeat;
  background-size: 16px 9px;
  background-position: center;
  border-radius: 100px;
  transform: rotate(180deg);
  transition: all 0.2s ease-out;
  cursor: pointer;
  @media screen and (min-width: $width-medium) {
    &:hover {
      background: transparent;
      background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 9" style="enable-background:new 0 0 16 9;" xml:space="preserve"><path id="Vector" class="st0" d="M8.1,9c0.2,0,0.3-0.1,0.4-0.2l7.2-7.2C15.9,1.4,16,1.2,16,1c0-0.2,0-0.5-0.2-0.7C15.7,0.1,15.5,0,15.3,0c-0.2,0-0.4,0.1-0.6,0.2L8,6.9L1.3,0.2C1.2,0.1,0.9,0,0.7,0C0.5,0,0.3,0.1,0.2,0.3C0.1,0.5,0,0.7,0,1c0,0.2,0.1,0.5,0.3,0.6l7.2,7.2C7.6,8.9,7.9,9,8.1,9L8.1,9z"/></svg>');
      border-color: $beige_b;
      background-repeat: no-repeat;
      background-size: 16px 9px;
      background-position: center;
    }
  }

  &.reverse {
    background: transparent;
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 9" style="enable-background:new 0 0 16 9;" xml:space="preserve"><path id="Vector" class="st0" d="M8.1,9c0.2,0,0.3-0.1,0.4-0.2l7.2-7.2C15.9,1.4,16,1.2,16,1c0-0.2,0-0.5-0.2-0.7C15.7,0.1,15.5,0,15.3,0c-0.2,0-0.4,0.1-0.6,0.2L8,6.9L1.3,0.2C1.2,0.1,0.9,0,0.7,0C0.5,0,0.3,0.1,0.2,0.3C0.1,0.5,0,0.7,0,1c0,0.2,0.1,0.5,0.3,0.6l7.2,7.2C7.6,8.9,7.9,9,8.1,9L8.1,9z"/></svg>');
    border-color: $beige_b;
    background-repeat: no-repeat;
    background-size: 16px 9px;
    background-position: center;
    @media screen and (min-width: $width-medium) {
      &:hover {
        background-color: $black;
        border: solid 1px $black;
        background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 9" style="enable-background:new 0 0 16 9;" xml:space="preserve"><path id="Vector" class="st0" d="M8.1,9c0.2,0,0.3-0.1,0.4-0.2l7.2-7.2C15.9,1.4,16,1.2,16,1c0-0.2,0-0.5-0.2-0.7C15.7,0.1,15.5,0,15.3,0c-0.2,0-0.4,0.1-0.6,0.2L8,6.9L1.3,0.2C1.2,0.1,0.9,0,0.7,0C0.5,0,0.3,0.1,0.2,0.3C0.1,0.5,0,0.7,0,1c0,0.2,0.1,0.5,0.3,0.6l7.2,7.2C7.6,8.9,7.9,9,8.1,9L8.1,9z"/></svg>');
        background-repeat: no-repeat;
        background-size: 16px 9px;
        background-position: center;
      }
    }
  }
}


.arrow-more {
  display: block;
  width: 50px;
  height:50px;
  background: $black;
  border: solid 1px $black;
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve"><path class="st0" d="M25.8,12.4v1.2H13.7v12.2h-1.4V13.6H0.2v-1.2h12.1V0.2h1.4v12.2H25.8z"/></svg>');
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: center;
  border-radius: 100px;
  transform: rotate(180deg);
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    background-color: $or;
    border-color: $or;
  }
}