.gutenberg-vigneron {

  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  border-bottom: solid 1px $beige_b;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../img/volute-right-bottom.svg);
    background-repeat: no-repeat;
    background-size: 70% auto;
    z-index: -1;
    background-position: right bottom;
    @media screen and (max-width: $width-medium) {
      background-size: 120% auto;
    }

  }



  h2 {
    text-align: center;
    @extend .h3;
    margin-bottom: 5rem;
  }

  h2.h4 {
    @extend .h4;
    text-align: left;
    margin-bottom: inherit;
    font-style: italic;
  }

  .vigneron-certifs, .certifs {
    margin-top: 3rem;
    display: flex;
    gap: 10px;

    img {
      height: 6rem !important;
      width: auto !important;
    }
  }


  .vigneron-localisation, .localisation {
    &:before {
      display: inline-block;
      content: "";
      background-image: url(../img/map.svg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 17px;
      height: 22px;
      margin-right: 1rem;
      @media screen and (max-width: $width-medium) {
        vertical-align: sub;
      }
    }
  }

  .vigneron-vin, .vin {
    display: block;
    font-family: $font-t;
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    margin-top: 3px;
    @media screen and (min-width: $width-medium) {
      margin-top: 8px;
    }
  }

  .vigneron-nom {
    font-style: italic;
  }

  #playButton, #playButtonMobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    &.active {
      display: block;
    }
  }

  @media screen and (min-width: $width-medium) {
    #playButton {
      margin-left: 3rem;
      &:hover {
        opacity: 0.7;
      }
    }
    #playButtonMobile {
      display: none !important;
    }
  }

  @media screen and (max-width: $width-medium) {
    #playButtonMobile {
      svg {
        width: 80px;
        height: 80px;
      }

    }
  }


  .swiper-navigation {
    margin-bottom: 3rem;
  }



  @media screen and (max-width: $width-medium) {
    .swiper-vigneron-thumb {
      padding-bottom: 6rem;

      .myPlayer {
        /*position: absolute;
        left: 0;
        top: 0;
        padding:75% 0 0 0;
        position:relative;*/

        &.inactive {
          opacity: 0;
        }
        iframe {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          pointer-events: none;
        }
      }

      .presentation {
        display: none;
        opacity: 0;
        transition: opacity 0.3s;
        margin-top: 7rem;
        margin-left: -5rem;
        margin-right: -5rem;
      width: 220px;
      }

      img, iframe {
        width: 100%;
        height: auto;
        transition: width 0.4s, height 0.4s;
      }

      .swiper-navigation {
        position: absolute;
        top: 87vw;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        width: 300px;

        .swiper-button-next, .swiper-button-prev {
          display: none;
        }
      }

      .swiper-slide-prev {
        text-align: right;
      }

      .swiper-slide {
        transition: opacity 0.3s, filter 0.3s;
      }

      .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 88vw;

        .video-valide {
          display: none;
        }

      }

      .swiper-slide-next {
        .container-img {
          justify-content: flex-start;
        }
      }

      .swiper-slide-prev {
        .container-img {
          justify-content: flex-end;
        }
      }


      .swiper-slide:not(.swiper-slide-active) {
        img, iframe {
          width: 65%;
        }
        &.swiper-slide-prev {
          iframe {
            left: inherit;
            right: 0;
          }
        }
      }

    }

    .vigneron-infos {
      display: flex !important;
      flex-direction: column-reverse;
    }

    .vigneron-description {
      display: block !important;
      margin-left: 3rem;
      margin-right: 3rem;

      .vigneron-description-text {
        margin-top: 3rem;
      }
    }
  }


 @media screen and (min-width: $width-medium) {
   .myPlayer {
     width: 100%;
     height: 100%;
     position: relative;
     min-height: 54vw;

     &.inactive {
       opacity: 0;
       height: 0;
       min-height: inherit;
     }
     iframe {
       position:absolute;
       top:0;
       left:0;
       width:100%;
       height:100%;
     }
   }

   .container-img {
     .myPlayer {
       display: none;
     }
   }


    .vigneron-photo {
      transition: opacity 0.2s ease-in-out;
    }

    .fade-out {
      opacity: 0;
    }

    .vigneron-img, .vigneron-description {
      display: block !important;
    }

   .vigneron-img img {
     &.inactive {
       display: none;
     }
   }

    .vigneron-infos {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-template-rows: auto 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }


    .vigneron-img {
     background: $beige;
      position: relative;
      z-index: 10;
      grid-area: 1 / 1 / 3 / 2;
      padding-left: 6rem;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .vigneron-description {
    padding-left: 12rem;
      padding-right: 12rem;
      padding-bottom: 5rem;
      grid-area: 1 / 2 / 2 / 3;
      min-height: 27vw;
    }

    .vigneron-description-text {
     margin-top: 3rem;
      max-width: 600px;
    }

    .vigneron-swiper {
     grid-area: 2 / 2 / 3 / 3;
    }


   .swiper-vigneron-thumb {
     float: left;
       width: 100%;
      margin-right: 60%;
     padding-bottom: 4vw;

     &.count-1 {
       .swiper-navigation {
         display: none;
       }
       .swiper-slide {
         display: none;
       }
     }

      .swiper-navigation {
        margin-top: 8rem;
        position: relative;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
      }

      .presentation {
        display: none;
      }

     .swiper-slide-last {
        display: block !important;
      }

     .swiper-button-prev, .swiper-button-next {
       /*top: 4px;*/
     }



      .swiper-slide {
        margin-top: 20px;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
        &.swiper-slide-active {
          box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.4);
          scale: 1.2;
        }
      }

    }
  }
}