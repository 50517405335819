.gutenberg-text-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  border-bottom: solid 1px $beige_b;
  @media screen and (min-width: $width-small) {
    flex-direction: row;
    &.right {
      flex-direction: row-reverse;
    }
  }

  .images, .texts {
    width: auto;
    align-self: stretch;
    @media screen and (min-width: $width-small) {
      width: 50%;
      flex-basis: 50%;
    }
  }
  .images {
    .content {
      aspect-ratio: 1;
      overflow: hidden;
      position: relative;
    }
    .bg {
      width: 100%;
      height: auto;
      display: block;
    }
    .photo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: auto;
      z-index: 10;
    }
    .logo {
      position: absolute;
      width: 20vw;
      height: auto;
      left: 50%;
      margin-left: -10vw;
      top: 16vw;
      z-index: 20;
      @media screen and (min-width: $width-small) {
          top: 8vw;
          left: inherit;
        margin-left: 0;
        right: 8vw;
        width: 10vw;
        height: auto;
      }
    }
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      position: absolute;
    }
  }
  .texts {
    padding: 5rem $padding;
    display: flex;
    align-items: center;
    h2 {
      @extend .h3;
    }
    @media screen and (min-width: $width-large) {
      padding: $padding-d;
    }
    .cta {
      margin-top: 3rem;
    }
    p {
      margin-bottom: 3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .subtitle {
    display: block;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-family: $font_t;
    margin-bottom: 2.5rem;
    margin-top: -2rem;
    line-height: 170%;
    font-size: 1.5rem;
  }
  &.small {
    @media screen and (min-width: $width-medium) {
      background-image: url(../img/volute-top.svg);
      background-repeat: no-repeat;
      background-size: 700px auto;
      background-position: 45% top;
    }
    .images {
      @media screen and (min-width: $width-medium) {
        width: 30%;
        flex-basis: 30%;
      }
    }
    .texts {
      text-align: center;
      background-image: url(../img/volute-top.svg);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: left top;
      @media screen and (min-width: $width-medium) {
        padding: 7rem 0;
        width: 70%;
        flex-basis: 70%;
        margin-left: auto;
        margin-right: auto;
        background: none;
        .swiper-slide {
          padding: 0 12rem;
          overflow: hidden;

          * {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .swiper-button-next, .swiper-button-prev {
      display: block !important;
    }
  }

  &.item-1.right {
    > .texts {
      position: relative;
      &:after {
        display: block;
        content:"";
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: url(../img/volute-cut-left.svg);
        background-repeat: no-repeat;
        background-size: 30% auto;
        background-position: right bottom;
        right: 0;
      }
    }
    > .images {
      .content {
        &:after {
          background-image: url(../img/volute-cut-right.svg);
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: block;
          content:"";
          width: 100%;
          height: 100%;
          position: absolute;
          background-position: right bottom;
          mix-blend-mode: soft-light;
          bottom: 0;
        }
      }
    }
  }

  &.item-2 {
    > .images {
      .content {
        &:after {
          background-image: url(../img/volute-cut-top.svg);
          background-repeat: no-repeat;
          background-size: 81% auto;
          display: block;
          content:"";
          width: 100%;
          height: 100%;
          position: absolute;
          background-position: left bottom;
          mix-blend-mode: soft-light;
          bottom: 0;
        }
      }
    }
  }

  &.item-3 {
    > .texts {
      position: relative;
        &:after {
          background-image: url(../img/volute-cut-bottom.svg);
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: block;
          content:"";
          width: 100%;
          height: 100%;
          position: absolute;
          background-position: left top;
          left: 0;
          top: 0;
        }
    }
  }

}

.sticky-contents {
  position: sticky;
  top: 0;
}