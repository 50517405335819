.tax-collection {
  .collection-introduction {
    text-align: center;
    border-top: solid 1px $beige_b;
    border-bottom: solid 1px $beige_b;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .content {
      padding: 0 $padding;
      margin-left: auto;
      margin-right: auto;
      max-width: 1260px;
    }
    .sous-titre {
      margin-bottom: 0;
    }

    .logo {
      margin-top: 2rem;
      margin-bottom: 3rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
      height: auto;
      max-height: 66px;
      width: auto;
    }
  }
  .gutenberg-text-img {
    .texts {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .subtitle {
        margin-bottom: 0.5rem;
        margin-top: 0;
      }
    }
    img {
      display: block;
      height: auto;
    }
    &.left {
      .images {
        @media screen and (min-width: $width-small) {
          border-right: solid 1px $beige_b;
        }
      }
      .texts {
        background-image: url(../img/volute-collection-text.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: right top;
        @media screen and (max-width: $width-small) {
          background-image: url(../img/volute-collection-text-mobile.svg);
          background-size: auto 100%;
        }
      }
    }
    &.right {
      .images {
        @media screen and (min-width: $width-small) {
          border-left: solid 1px $beige_b;
        }
      }
    }
  }

}


.collection-produits {
  .h3 {
    display: block;
    padding: 5rem $padding;
    font-size: 2.2rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.1px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    border-top: solid 1px $beige_b;
  }
}

.miniature-vin {
  background: $beige;
  transition: background 0.2s;
  border-bottom: solid 1px $beige_b;
  transition: background 1s;
  @media screen and (max-width: $width-small) {
    width: 50%;
    &:nth-child(odd) {
      border-right: solid 1px $beige_b;
    }
  }
  @media screen and (min-width: $width-small) {
    width: 25%;
    border-left: solid 0.5px $beige_b;
    border-right: solid 0.5px $beige_b;
  }

  .item-vin {
    background-position: center;
    background-size: auto 100%;
    padding: 1rem 0;
    background-repeat: no-repeat;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    transition: background-image 0.5s ease; /* Ajout de la transition */
    @media screen and (max-width: $width-small) {
      height: 50vw;
    }
    @media screen and (min-width: $width-small) {
      height: 24.9vw;
    }

    img {
      max-height: 100%;
      width: auto;
      position: relative;
    }

  }

  .sous-titre {
    border-top: solid 1px $beige_b;
    padding: 1rem;
    padding-bottom: 0;
    background: $beige;
    transition: background 1s;
  }

  h2, .h2 {
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    background: $beige;
    transition: background 1s;
    display: block;
  }

  &:hover {
    background: $white;
    .sous-titre, h2 {
      background: $white;
    }
  }

}

.gutenberg-hero-title {
  &.collection {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media screen and (max-width: $width-small) {
      height: 465px;
    }
    @media screen and (min-width: $width-small) {
      height: 100vh;
    }
    &:after, &:before {
      display: none;
    }
  }
}