#breadcrumb {
  padding: 0 2rem;
  position: absolute;
  top: 5rem;
  z-index: 100;
  font-size: 1rem;
  color: $black;
  @media screen and (min-width: $width-medium) {
    padding: 0 5.5rem;
    top: 6.5rem;
  }

  a, span {
    color: rgba(43, 40, 41, 0.50);
  }

}

.breadcrumb-white {
  #breadcrumb {
    color: $white;
    a, span {
      color: rgba(252, 250, 250, 0.50);
    }
  }
}